import { useState, useContext, useEffect } from "react";
import { FaCogs, FaEdit, FaExclamationTriangle, FaFileUpload, FaGlobe, FaPlusCircle, FaTrashAlt, FaUpload } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import Dialogs from "components/ui/dialogs/dialogs";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import RGProvider, { RGContext } from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/RGProvider";
import AjoutPeriodePageModal from "pages/audit complet/retraite/ajout periode modal";
import AjoutRISPageModal from "pages/audit complet/retraite/ajout RIS modal";
import ParamRetraitePageModal from "pages/audit complet/retraite/parametrage retraite";

const CarrierePage = (props) => {
  const CorrespondanceActivite = {
    ActiviteSalarie: "Activité salariée",
    SalarieNonCadre: "Salarié non cadre du  secteur privé",
    SalarieCadre: "Salarié cadre du  secteur privé",
    SalarieNonCadreCFE: "Salarié non cadre cotisant CFE",
    SalarieCadreCFE: "Salarié cadre cotisant CFE",
    SalarieNonCadreAgricole: "Salarié non cadre du secteur agricole",
    SalarieCadreAgricole: "Salarié cadre du secteur agricole",
    SalarieIrcantec: "Salarié non titulaire de l’Etat",
    CavecSalarie: "Expert comptable salarié",
    CnbfSalarie: "Avocat salarié",
    FonctionPublique: "Fonction publique",
    Avpf: "Assurance vieillesse des parents au foyer",
    Chomage: "Chômage et assimilé",
    Maladie: "Maladie",
    Maternite: "Maternité",
    Invalidite: "Invalidité",
    Militaire: "Militaire, guerre",
    TrimSpecifique: "Trimestres spécifiques",
    RsiArtisan: "Artisan",
    Cnbf: "Avocat",
    Carpimko: "Auxiliaire médical",
    Carcdsf: "Chirurgien-dentiste",
    RsiCommercant: "Commerçant",
    MicroRSI: "Micro-entrepreneur ",
    MicroBIC: "Micro-entrepreneur ",
    MicroBNC: "Micro-entrepreneur ",
    MicroCipav: "Micro-entrepreneur ",
    Cavec: "Expert-comptable",
    Cavamac: "Agent général",
    Crn: "Notaire",
    Msa: "Exploitant agricole",
    Cipav: "Ingénieur conseil",
    Cavom: "Officier min., pub. ou judi.",
    Carmf: "Médecin",
    Cavp: "Pharmacien",
    Carcdsf2: "Sage-femme",
    Carpv: "Vétérinaire",
    Crpcen: "Clerc de notaire",
  };
  const context = useContext(AppContext);
  const [clientConjoint, setClientConjoint] = useState("");
  const [modalPeriodeRetr, setmodalPeriodeRetr] = useState(false);
  const [modalRISRetr, setmodalRISRetr] = useState(false);
  const [modalParamRetrOpen, setmodalParamRetrOpen] = useState(false);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const Periode = context.state["PeriodeActuelle"][0];
  const PeriodeConjoint = context.state["PeriodeActuelleConjoint"][0];
  useEffect(() => {
   
    if (context.state.ApiUser === true) {
      context.GetDateDepartRetraite("", context.state);
      context.GetDateDepartRetraite("Conjoint", context.state);
    }

    if (Periode !== undefined) {
      if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "").then(() => {});
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "").then(() => {});
      }

      if (context.state.InclureConjoint === true && Periode?.StatutConjoint !== "Aucun") {
        if (Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel") {
          ResultatTNS.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {});
        } else {
          ResultatSalarie.GetResultRemExpert(context.state, "ConjointAssocie").then(() => {});
        }
      }
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "Conjoint").then(() => {});
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "Conjoint").then(() => {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if(props.ClientConjoint===""){
      setClientConjoint("");
    }else{
      setClientConjoint("Conjoint");
    }

  }, [props.ClientConjoint]);
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox bpad>
              <Column fill>
                <Row>
                  <h1>Carrière {context.state["Prenom"+clientConjoint]}</h1>
                </Row>
                <Row center>
                  <Column>
                    <Button
                      onClick={() => {
                        setmodalRISRetr(true);
                      }}
                    >
                      <span>Importer le RIS pour {context.state["Prenom"+clientConjoint]}</span>
                      <FaUpload />
                    </Button>
                  </Column>
                  <Column style={{ paddingLeft: 0 }}>
                    <Button
                      onClick={() => {
                        context.handleResetPeriodeRetraite(clientConjoint,"");
                        setmodalPeriodeRetr(true);
                      }}
                    >
                      <span>Ajouter une période pour {context.state["Prenom"+clientConjoint]}</span>
                      <FaPlusCircle />
                    </Button>
                  </Column>

                  <Column>
                    <Button
                      onClick={() => {
                        setmodalParamRetrOpen(true);
                      }}
                    >
                      <span>Paramétrer la retraite pour {context.state["Prenom"+clientConjoint]}</span>
                      <FaCogs id="paramRetraite" />
                    </Button>
                  </Column>
                </Row>
                <Row tpad style={{ maxHeight: "500px", overflow: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Date de début</th>
                        <th>Date de fin</th>
                        <th>Activité</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.state["Carriere"+clientConjoint].map((Infos, Index) => {
                        return (
                          <tr key={Index}>
                            <td>{applyFormat(Infos.Debut, "date", "")}</td>
                            <td>{applyFormat(Infos.Fin, "date", "")}</td>
                            <td>{CorrespondanceActivite[Infos.Nature]}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  context.handleModifyDeletePeriodeRetraite(Infos.id, "Modifier", "", clientConjoint);
                                  setmodalPeriodeRetr(true);
                                }}
                                icon
                                style={{ display: "inline-block" }}
                              >
                                <FaEdit />
                              </Button>
                              {Infos.id !== "CarriereActuelle" && (
                                <Button
                                  onClick={() => {
                                    Dialogs.confirm("Vous êtes sur le point de supprimer la période.\nVoulez-vous continuer ?", "Suppression de la période", () => {
                                      context.handleModifyDeletePeriodeRetraite(Infos.id, "Supprimer", "", clientConjoint);
                                    });
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaTrashAlt className="delete" />
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Row>
                <Row tpad>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Synthèse de la retraite pour {context.state["Prenom"+clientConjoint]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>Trimestres retenus</td>
                        <td>{context.state["TotalTrimestre"+clientConjoint]}</td>
                      </tr>
                      <tr>
                        <td>Age de départ en retraite</td>
                        <td>
                          <Row>
                            <Column style={{ padding: 0 }}>{context.state["AgeDepartRetraite"+clientConjoint]}</Column>
                            <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                              <Button
                                icon
                                style={{ display: "inline-block" }}
                                onClick={() => {
                                  setmodalParamRetrOpen(true);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </Column>
                            <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                              <Button
                                icon
                                style={{ display: "inline-block", color: "red" }}
                                onClick={() => {
                                  setmodalParamRetrOpen(true);
                                }}
                              >
                              </Button>
                            </Column>
                          </Row>
                        </td>
                      </tr>
                      <tr>
                        <td>Date de départ en retraite</td>
                        <td>{context.state["DateDepartRetraiteAffichage"+clientConjoint]}</td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>

        {/* {context.state.InclureConjoint && (
          <Row>
            <Column fill>
              <CardBox tpad>
                <Column fill>
                  <Row>
                    <h1>Carrière {context.state.PrenomConjoint}</h1>
                  </Row>
                  <Row center>
                    <Column>
                      <Button
                        onClick={() => {
                          setClientConjoint("Conjoint");
                          setmodalRISRetr(true);
                        }}
                      >
                        <span>Importer le RIS pour {context.state.PrenomConjoint}</span>
                        <FaUpload />
                      </Button>
                    </Column>
                    <Column style={{ paddingLeft: 0 }}>
                      <Button
                        onClick={() => {
                          setClientConjoint("Conjoint");
                          context.handleResetPeriodeRetraite("Conjoint","");
                          setmodalPeriodeRetr(true);
                        }}
                      >
                        <span>Ajouter une période pour {context.state.PrenomConjoint}</span>
                        <FaPlusCircle />
                      </Button>
                    </Column>

                    <Column>
                      <Button
                        onClick={() => {
                          setClientConjoint("Conjoint");
                          setmodalParamRetrOpen(true);
                        }}
                      >
                        <span>Paramétrer la retraite pour {context.state.PrenomConjoint}</span>
                        <FaCogs id="paramRetraiteConjoint" />
                      </Button>
                    </Column>
                  </Row>
                  <Row tpad>
                    <table>
                      <thead>
                        <tr>
                          <th>Date de début</th>
                          <th>Date de fin</th>
                          <th>Activité</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {context.state.CarriereConjoint.map((Infos, Index) => {
                          return (
                            <tr key={"Conj" + Index}>
                              <td>{applyFormat(Infos.Debut, "date", "")}</td>
                              <td>{applyFormat(Infos.Fin, "date", "")}</td>
                              <td>{CorrespondanceActivite[Infos.Nature]}</td>
                              <td>
                                <Button
                                  onClick={() => {
                                    setClientConjoint("Conjoint");
                                    context.handleModifyDeletePeriodeRetraite(Infos.id, "Modifier", "", "Conjoint");
                                    setmodalPeriodeRetr(true);
                                  }}
                                  icon
                                  style={{ display: "inline-block" }}
                                >
                                  <FaEdit />
                                </Button>
                                {Infos.id !== "CarriereActuelle" && (
                                  <Button
                                    onClick={() => {
                                      setClientConjoint("Conjoint");
                                      Dialogs.confirm("Vous êtes sur le point de supprimer la période.\nVoulez-vous continuer ?", "Suppression de la période", () => {
                                        context.handleModifyDeletePeriodeRetraite(Infos.id, "Supprimer", "", "Conjoint");
                                      });
                                    }}
                                    icon
                                    style={{ display: "inline-block" }}
                                  >
                                    <FaTrashAlt className="delete" />
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Row>
                  <Row tpad>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={2}>Synthèse de la retraite pour {context.state.PrenomConjoint}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>Trimestres retenus</td>
                          <td>{context.state.TotalTrimestreConjoint}</td>
                        </tr>
                        <tr>
                          <td>Age de départ en retraite</td>
                          <td>
                            <Row>
                              <Column style={{ padding: 0 }}>{context.state.AgeDepartRetraiteConjoint}</Column>
                              <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                                <Button
                                  icon
                                  style={{ display: "inline-block" }}
                                  onClick={() => {
                                    setClientConjoint("Conjoint");
                                    setmodalParamRetrOpen(true);
                                  }}
                                >
                                  <FaEdit />
                                </Button>
                              </Column>
                              <Column style={{ padding: 0, alignSelf: "flex-end", marginTop: "0px" }}>
                                <Button
                                  icon
                                  style={{ display: "inline-block", color: "red" }}
                                  onClick={() => {
                                    setClientConjoint("Conjoint");
                                    setmodalParamRetrOpen(true);
                                  }}
                                >
                                </Button>
                              </Column>
                            </Row>
                          </td>
                        </tr>
                        <tr>
                          <td>Date de départ en retraite</td>
                          <td>{context.state.DateDepartRetraiteAffichageConjoint}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                </Column>
              </CardBox>
            </Column>
          </Row>
        )} */}
      </Column>

      <Modal
        width="80%"
        height="80%"
        title="Ajouter une période"
        icon={<FaGlobe />}
        visible={modalPeriodeRetr}
        onClose={() => {
          setmodalPeriodeRetr(false);
        }}
      >
        <AjoutPeriodePageModal
          Outil=""
          ClientConjoint={clientConjoint}
          visible={modalPeriodeRetr}
          onClose={() => {
            setmodalPeriodeRetr(false);
          }}
        />
      </Modal>
      <Modal
        width="80%"
        height="80%"
        title="Lecture du relevé de carrière"
        icon={<FaGlobe />}
        visible={modalRISRetr}
        onClose={() => {
          setmodalRISRetr(false);
        }}
      >
        <AjoutRISPageModal
          Outil=""
          ClientConjoint={clientConjoint}
          visible={modalRISRetr}
          onClose={() => {
            setmodalRISRetr(false);
          }}
        />
      </Modal>
      <RGProvider ClientConjoint={clientConjoint} visible={modalParamRetrOpen}>
        <RGContext.Consumer>
          {(RGCtx) => (
            <Modal
              width="80%"
              height="80%"
              title="Paramétrage de la retraite"
              icon={<FaGlobe />}
              visible={modalParamRetrOpen}
              onClose={() => {
                setmodalParamRetrOpen(false);
              }}
            >
              <ParamRetraitePageModal
                Outil=""
                ClientConjoint={clientConjoint}
                visible={modalParamRetrOpen}
                onClose={() => {
                  setmodalParamRetrOpen(false);
                }}
              />
            </Modal>
          )}
        </RGContext.Consumer>
      </RGProvider>
    </>
  );
};

export default CarrierePage;
