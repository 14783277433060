const Today = new Date();

export const RemExpertEnvironnementJuridique = {
  //ENVIRONNEMENT JURIDIQUE
  SwitchClient: "client",
  IdentiteEntreprise: "",
  FormeJuridique: "EURL",
  Gerance: "Majoritaire",
  Fiscalite: "IS",
  TauxISReduit: true,
  TauxISNormal: false,
  TauxISZero: false,
};
export const caisse = [
  {
    value: "RsiCommercant",
    label: "SSI Commerçant",
  },
  {
    value: "RsiArtisan",
    label: "SSI Artisan",
  },
  {
    value: "Carcdsf",
    label: "CARCD",
  },
  {
    value: "Carcdsf2",
    label: "CARSF",
  },
  {
    value: "Carmf",
    label: "CARMF",
  },
  {
    value: "Carpimko",
    label: "CARPIMKO",
  },
  {
    value: "Carpv",
    label: "CARPV",
  },
  {
    value: "Cavec",
    label: "CAVEC",
  },
  {
    value: "Cavp",
    label: "CAVP",
  },
  {
    value: "Cipav",
    label: "CIPAV",
  },
  {
    value: "Cnbf",
    label: "CNBF",
  },
  {
    value: "Crn",
    label: "CRN",
  },
  {
    value: "Msa",
    label: "MSA",
  },
];
export const caisseSELARL = [
  {
    value: "Carcdsf",
    label: "CARCD",
  },
  {
    value: "Carcdsf2",
    label: "CARSF",
  },
  {
    value: "Carmf",
    label: "CARMF",
  },
  {
    value: "Carpimko",
    label: "CARPIMKO",
  },
  {
    value: "Carpv",
    label: "CARPV",
  },
  {
    value: "Cavec",
    label: "CAVEC",
  },
  {
    value: "Cavp",
    label: "CAVP",
  },
  {
    value: "Cipav",
    label: "CIPAV",
  },
  {
    value: "Cnbf",
    label: "CNBF",
  },
  {
    value: "Crn",
    label: "CRN",
  },
];
export const RemExpertPeriodeActuelle = {
  IdPeriodeActuelle: "",
  PeriodeActuelle: [],
  PeriodeActuelleConjoint: [],
};
export const RemExpertFinPeriodeActuelle = {
  FinPeriodeCarriereActuelle: "",
  FinPeriodeCarriereActuelleConjoint: "",
};
export const RemExpertEnvironnementSocial = {
  //ENVIRONNEMENT SOCIAL
  SituationPro: "Tns",
  DebutPeriodeCarriereActuelle: "0101" + Today.getFullYear(),
  FinCarriereActuelle: "DepartRetraite",
  FinCarriereActuelleConjoint: "DepartRetraite",
  Statut: "TNS Article 62",
  StatutConjoint: "Aucun",
  // Statut: "Assimilé salarié",
  Caisse: "RsiCommercant",
  ModeExercice: "IR",
  PartRemunerationMandat: 5,
  ConventionneCarcdsf: "oui",
  ConventionneCarmf: "non",
  OptionRsi: "non",
  ConventionneCarpimko: "oui",
  TauxCpam: 100,
  ClassePrevoyanceCarpv: "minimum",
  ClasseRetraiteCarpv: "SS1",
  ClassePrevoyanceCavec: "NC",
  ClasseRetraiteCavec: "NC",
  ConventionneCavp: "oui",
  ClasseRetraiteCavp: "classe3",
  ClassePrevoyanceCipav: "A",
  ClasseRetraiteCipav: "NC",
  AncienneteCnbf: "1",
  ClasseRetraiteCnbf: "C1",
  LpaMontantIncap: 90,
  LpaFranchiseIncapDebut: 30,
  LpaFranchiseIncapFin: 90,
  LpaInvalPartielle: 13720,
  LpaInvalTotale: 9332.5,
  ClasseRetraiteCrn: "NC",
  CrnSermentAvt2014: false,
  CrnColmarMetz: false,
  Atexa: "A",
  Effectif: "-11",
  CavecSalarie: "Non",
  CnbfSalarie: "Non",
  AccidentTravail: 1.1,
  AgircArrcoT1Sal: 3.15,
  AgircArrcoT1Pat: 4.72,
  AgircArrcoT2Sal: 8.64,
  AgircArrcoT2Pat: 12.95,
  Transport: 0,
  TauxActivite: 100,
  TauxActiviteConjoint: 100,
  Formation: "non",
  Apprentissage: "non",
  Regime: "non",
  TaxeSalaire: "non",
  AssuranceChomage: "non",
  ReductionGenerale: "non",
  AvantageNature: 0,
  TabCotisationsManuelle: [],
  PartRemunerationMandatConjoint:5,
  ConventionneCarcdsfConjoint: "oui",
  ConventionneCarmfConjoint: "non",
  OptionRsiConjoint: "non",
  ConventionneCarpimkoConjoint: "oui",
  TauxCpamConjoint: 100,
  ClassePrevoyanceCarpvConjoint: "minimum",
  ClasseRetraiteCarpvConjoint: "SS1",
  ClassePrevoyanceCavecConjoint: "NC",
  ClasseRetraiteCavecConjoint: "NC",
  ConventionneCavpConjoint: "oui",
  ClasseRetraiteCavpConjoint: "classe3",
  ClassePrevoyanceCipavConjoint: "A",
  ClasseRetraiteCipavConjoint: "NC",
  AncienneteCnbfConjoint: "1",
  ClasseRetraiteCnbfConjoint: "C1",
  LpaMontantIncapConjoint: 90,
  LpaFranchiseIncapDebutConjoint: 30,
  LpaFranchiseIncapFinConjoint: 90,
  LpaInvalPartielleConjoint: 13720,
  LpaInvalTotaleConjoint: 8320,
  ClasseRetraiteCrnConjoint: "NC",
  CrnSermentAvt2014Conjoint: false,
  CrnColmarMetzConjoint: false,
  MoyenneProduitCrnConjoint: 0,
  AtexaConjoint: "A",
  EffectifConjoint: "-11",
  CavecSalarieConjoint: "Non",
  CnbfSalarieConjoint: "Non",
  AccidentTravailConjoint: 1.1,
  AgircArrcoT1SalConjoint: 3.15,
  AgircArrcoT1PatConjoint: 4.72,
  AgircArrcoT2SalConjoint: 8.64,
  AgircArrcoT2PatConjoint: 12.95,
  TransportConjoint: 0,
  FormationConjoint: "non",
  ApprentissageConjoint: "non",
  RegimeConjoint: "non",
  TaxeSalaireConjoint: "non",
  AssuranceChomageConjoint: "non",
  ReductionGeneraleConjoint: "non",
  AvantageNatureConjoint: 0,
  TabCotisationsManuelleConjoint: [],
  LibelleCotisationManuelle: "",
  AssietteCotisationManuelle: "Salaire brut",
  TypeCotisationManuelle: "proportionnelle",
  TauxSalarieCotisationManuelle: 0.0,
  TauxPatronaleCotisationManuelle: 0.0,
};

export const RemExpertRevenusActivite = {
  //REVENUS D'ACTIVITE
  TypeRemuneration: "remuneration_nette",
  TypeRemunerationConjoint: "remuneration_nette",
  // TypeRemuneration: "salaire_brut",
  Remuneration: 0,
  RemunerationConjoint: 0,
  DividendeBrut: 0,
  DividendeBrutAutres: 0,
  TNSIndividuelRegimeApplicable: "BICReel",
  CapitalSocial: 0,
  PartSoc: 100,
  PartSocConj: 0,
  ResAvtIs: 0,
  ImpotSoc: 0,
  ResNet: 0,
  SeuilAssuj: 0,
  SeuilAssujConjoint: 0,
  SetSeuilAssuj: false,
  SetSeuilAssujConjoint: false,
  CCAVal: 0,
  AcompteDividende: "oui",
  Reserve: 0,
  AbattementPYL: "non",
  ForfaitSocialInteressement: 0,
  ForfaitSocialParticipation: 0,
  ForfaitSocialAbondementPee: 0,
  ForfaitSocialAbondementPerco: 0,
};
export const RemExpertForfaitSocial = {
  ForfaitSocialInteressement: 0,
  ForfaitSocialParticipation: 0,
  ForfaitSocialAbondementPee: 0,
  ForfaitSocialAbondementPerco: 0,
};
export const RemExpertRevenusFoyer = {
  //REVENUS DU FOYER
  OptionPfu: "oui",
  AutresRevenus: 0,
  DeductionFiscale: 0,
  ReductionImpot: 0,
  IRDirigeantTraitement: 0,
  IRConjointTraitement: 0,
  IRDirigeantAutresRevenus: 0,
  IRConjointAutresRevenus: 0,
  IRDirigeantAbattement: "Forfaitaire",
  IRConjointAbattement: "Forfaitaire",
  IRDirigeantFraisReel: 0,
  IRConjointFraisReel: 0,
  IRDirigeantBNCMicro: 0,
  IRConjointBNCMicro: 0,
  IRDirigeantBNCControlle: 0,
  IRConjointBNCControlle: 0,
  IRDirigeantBICMicroAchatRevente: 0,
  IRConjointBICMicroAchatRevente: 0,
  IRDirigeantBICMicroService: 0,
  IRConjointBICMicroService: 0,
  IRDirigeantBICReel: 0,
  IRConjointBICReel: 0,
  IRDirigeantBAMicro: 0,
  IRConjointBAMicro: 0,
  IRDirigeantBAReel: 0,
  IRConjointBAReel: 0,
  IRDirigeantRetraite: 0,
  IRConjointRetraite: 0,
  IRDirigeantInvalidite: 0,
  IRConjointInvalidite: 0,
  IRDirigeantAlimentaire: 0,
  IRConjointAlimentaire: 0,
  IRDirigeantCapital: 0,
  IRConjointCapital: 0,
  IRDirigeantRenteMoins50: 0,
  IRDirigeantRente5059: 0,
  IRDirigeantRente6069: 0,
  IRDirigeantRente70: 0,
  IRDirigeantAutreDividendeSoumisPS: 0,
  IRDirigeantAutreDividendeNonSoumisPS: 0,
  IRDirigeantPlusValue: 0,
  IRDirigeantMoinsValue: 0,
  IRDirigeantAbattementDureeDetention: 0,
  IRDirigeantAbattementDepartRetraite: "Non",
  IRDirigeantRecette: 0,
  IRDirigeantRegimeRevenuFoncier: "Micro",
  IRDirigeantChargeRevenuFoncier: 0,
  IRConjointCotisationPerp: 0,
  IRDirigeantPlafond1: 0,
  IRConjointPlafond1: 0,
  IRDirigeantPlafond2: 0,
  IRConjointPlafond2: 0,
  IRDirigeantPlafond3: 0,
  IRConjointPlafond3: 0,
  IRDirigeantPlafondNM1: 0,
  SetIRDirigeantPlafondNM1: false,
  IRConjointPlafondNM1: 0,
  SetIRConjointPlafondNM1: false,
  IRDirigeantAutresRevenusSoumisPSAvecCSGDed: 0,
  IRDirigeantAutresRevenusSoumisPSSansCSGDed: 0,
  IRCSGDedRevenuPatrimoine: 0,
  IRPensionAlimEnfant1: 0,
  IRPensionAlimEnfant2: 0,
  IRAutrePensionAlimEnfant1: 0,
  IRAutrePensionAlimEnfant2: 0,
  IRPensionAlim: 0,
  IRAutrePensionAlim: 0,
  IRDonPersonne: 0,
  IRDonAssociation: 0,
  IREmploiDomicile: 0,
  IRFraisGardeEnfantExclusive1: 0,
  IRFraisGardeEnfantPartagee1: 0,
  IRFraisGardeEnfantExclusive2: 0,
  IRFraisGardeEnfantPartagee2: 0,
  IRFraisGardeEnfantExclusive3: 0,
  IRFraisGardeEnfantPartagee3: 0,
  IREtudeEnfantCollegeExclusive: 0,
  IREtudeEnfantCollegePartagee: 0,
  IREtudeEnfantLyceeExclusive: 0,
  IREtudeEnfantLyceePartagee: 0,
  IREtudeEnfantFacExclusive: 0,
  IREtudeEnfantFacPartagee: 0,
};

export const RemExpertContratEnregistrePrevoyance = {
  ContratEnregistre: [],
  ContratFraisPro: [],
  ContratEnregistreConjoint: [],
  ContratFraisProConjoint: [],
};

export const Tabij1 = [
  {
    value: 0,
    label: "0 jours",
  },
  {
    value: "8",
    label: "8 jours",
  },
  {
    value: "16",
    label: "16 jours",
  },
  {
    value: "31",
    label: "31 jours",
  },
  {
    value: "61",
    label: "61 jours",
  },
  {
    value: "91",
    label: "91 jours",
  },
  {
    value: "181",
    label: "181 jours",
  },
  {
    value: "366",
    label: "366 jours",
  },
  {
    value: "721",
    label: "721 jours",
  },
  {
    value: "731",
    label: "731 jours",
  },
];
export const Tabij2 = [
  {
    value: "7",
    label: "7 jours",
  },
  {
    value: "15",
    label: "15 jours",
  },
  {
    value: "30",
    label: "30 jours",
  },
  {
    value: "60",
    label: "60 jours",
  },
  {
    value: "90",
    label: "90 jours",
  },
  {
    value: "180",
    label: "180 jours",
  },
  {
    value: "360",
    label: "360 jours",
  },
  {
    value: "365",
    label: "365 jours",
  },
  {
    value: "720",
    label: "720 jours",
  },
  {
    value: "730",
    label: "730 jours",
  },
  {
    value: "1095",
    label: "1095 jours",
  },
];
export const RemExpertPrevoyance = {
  IdContratPrevoyance: "",
  SelectContrat: "New",
  LibelleContrat: "",
  PayeurPrimePrevoyance: "Entreprise",
  PrevoyanceMadelin: 0,
  PrevoyanceNonMadelin: 0,
  Tarification: "Evolutive avec l'âge",
  ContratPrevoyance: "indemnitaire",
  Nature: "Indemnitaire",
  LimiteNatureContrat: 0,
  LimiteNatureContratUnite: "€",
  AssietteGarantie: 0,
  Taux83Prevoyance: {
    taux_prevoyance_trancheA_salarie83: 0.0,
    taux_prevoyance_trancheA_patronale83: 0.0,
    taux_prevoyance_trancheB_salarie83: 0.0,
    taux_prevoyance_trancheB_patronale83: 0.0,
    taux_prevoyance_trancheC_salarie83: 0.0,
    taux_prevoyance_trancheC_patronale83: 0.0,
    taux_prevoyance_trancheD_salarie83: 0.0,
    taux_prevoyance_trancheD_patronale83: 0.0,
  },
  //OPTION/EXCLUSION
  RachatPsy: "Non",
  RachatPsyCarence: "NC",
  RachatPsyDureeHospi: "NC",
  RachatPsyDureeIJ: "NC",
  RachatDos: "Non",
  RachatDosCarence: "NC",
  RachatDosDureeHospi: "NC",
  RachatDosDureeIJ: "NC",
  MiTempsTherapeutique: "Non",
  MTPCarence: "NC",
  MTPDureeIJ: "NC",
  PECDos: "Non",
  PECDosCarence: "NC",
  PECDosDureeHospi: "NC",
  PECDosDureeIJ: "NC",
  PECPsy: "Non",
  PECPsyCarence: "NC",
  PECPsyDureeHospi: "NC",
  PECPsyDureeIJ: "NC",
  //INCAP
  TauxIJ: 100,
  MaladieComplementaire: 0,
  PourcentageMaladieComplementaireSalarie: 0.0,
  BaseMaladieComplementaireSalarie: "SalaireBrut",
  IJ1: "31",
  IJ2: "1095",
  Tabij1: Tabij1,
  Tabij2: Tabij2,
  Tabij4: Tabij2,
  Tabij6: Tabij2,
  MaladieComplementaire2: 0,
  IJ3: "",
  IJ4: "1095",
  MaladieComplementaire3: "",
  IJ5: "",
  IJ6: "1095",
  FranchiseAccidentComplementaire: 3,
  FranchiseHospitalisationComplementaire: 3,
  ApplicationFranchiseHospi: "Non communiqué",
  CapitalMaladieRedoutee: 0,
  //INVAL
  InvaliditeTotaleComplementaire: 0,
  TauxInvaliditeTotale: 100,
  CapitalInvalTotale: 0,
  InvaliditePartielleComplementaire: 33,
  CalculRente: "T/66",
  TauxInvalPartielleComplementaire15: 51,
  TauxInvalPartielleComplementaire33: 51,
  TauxInvalPartielleComplementaire66: 85,
  Bareme: "Invalidité professionnelle",
  BaseInvaliditeComplementaireSalarie: "SalaireNet",
  PourcentageInvaliditeCategorie1: 0.0,
  PourcentageInvaliditeCategorie2: 0.0,
  PourcentageInvaliditeCategorie3: 0.0,
  //DECES
  DecesTouteCause: 0,
  TauxDecesTouteCause: "300",
  DecesAccidentel: 0,
  TauxDecesAccidentel: "600",
  DecesDoubleEffet: 0,
  FraisObseque: 0,
  RenteConjoint: 0,
  TauxRenteConjoint: 0,
  DecesAgeDebut1: 0,
  DecesAgeFin1: 11,
  DecesAgeDebut2: 12,
  DecesAgeFin2: 17,
  DecesAgeDebut3: 18,
  DecesAgeFin3: 25,
  RenteEducation0_11: 0,
  TauxRenteEducation0_11: 0,
  RenteEducation12_17: 0,
  TauxRenteEducation12_17: 0,
  RenteEducation18_25: 0,
  TauxRenteEducation18_25: 0,
  BaseDecesComplementaireSalarie: "SalaireBrut",
  PourcentageDecesTouteCause: 0.0,
  PourcentageDecesAccidentel: 0.0,
  BaseRenteConjointComplementaireSalarie: "SalaireBrut",
  PourcentageRenteConjoint: 0.0,
  BaseRenteEducationComplementaireSalarie: "SalaireBrut",
  PourcentageRenteEducation0_11: 0.0,
  PourcentageRenteEducation12_17: 0.0,
  PourcentageRenteEducation18_25: 0.0,

  //FGP
  FGPLibelle: "",
  FGPMontant: 0,
  FGPIJ: 0,
  FGPFranchiseMaladie: 30,
  FGPFranchiseAccident: 3,
  FGPFranchiseHospitalisation: 3,
  FGPDureeVersement: 365,
};

export const RemExpertContratEnregistreSante = {
  ContratEnregistreSante: [],
  ContratEnregistreSanteConjoint: [],
};
export const RemExpertSante = {
  IdContratSante: "",
  LibelleContratSante: "",
  PayeurPrimeSante: "Entreprise",
  SanteMadelin: 0,
  SanteNonMadelin: 0,
  Taux83Sante: {
    taux_sante_salarie83: 0.0,
    taux_sante_patronale83: 0.0,
  },
};

export const RemExpertDateDepartRetraite = {
  ChoixDepartRetraite: "DepartAgeLegal",
  ChoixDepartRetraiteConjoint: "DepartAgeLegal",
  UnlockAgeOuverture: false,
  UnlockAgeOuvertureConjoint: false,
  DepartAgeLegal: true,
  DepartAgeLegalConjoint: true,
  DepartAgeChoisi: false,
  DepartAgeChoisiConjoint: false,
  DepartDateChoisi: false,
  DepartDateChoisiConjoint: false,
  DepartAgeTauxPlein: false,
  DepartAgeTauxPleinConjoint: false,
  DepartAgeChoisiSelect: "64",
  DepartMoisChoisiSelect: 0,
  MoisDepartDateChoisiInput: "",
  AnneeDepartDateChoisiInput: "",
  DepartAgeChoisiSelectConjoint: "64",
  DepartMoisChoisiSelectConjoint: 0,
  MoisDepartDateChoisiInputConjoint: "",
  AnneeDepartDateChoisiInputConjoint: "",
  AgeDepartProjection: 64,
  AgeDepartProjectionConjoint: 64,

  CumulEmploiDepartAgeChoisi: false,
  CumulEmploiDepartDateChoisi: false,
  CumulEmploiDepartAgeChoisiSelect: "64",
  CumulEmploiDepartMoisChoisiSelect: 0,
  CumulEmploiMoisDepartDateChoisiInput: "",
  CumulEmploiAnneeDepartDateChoisiInput: "",

  CumulEmploiDepartAgeChoisiConjoint: false,
  CumulEmploiDepartDateChoisiConjoint: false,
  CumulEmploiDepartAgeChoisiSelectConjoint: "64",
  CumulEmploiDepartMoisChoisiSelectConjoint: 0,
  CumulEmploiMoisDepartDateChoisiInputConjoint: "",
  CumulEmploiAnneeDepartDateChoisiInputConjoint: "",
};

export const InitialListeActiviteRetraite = [
  {
    label: "Activités salariées",
    options: [
      {
        label: "Salarié non cadre du secteur privé",
        value: "SalarieNonCadre",
      },
      { label: "Salarié cadre du secteur privé", value: "SalarieCadre" },
      {
        label: "Salarié non cadre du secteur agricole",
        value: "SalarieNonCadreAgricole",
      },
      {
        label: "Salarié cadre du secteur agricole",
        value: "SalarieCadreAgricole",
      },
      { label: "Salarié non cadre cotisant CFE", value: "SalarieNonCadreCFE" },
      { label: "Salarié cadre cotisant CFE", value: "SalarieCadreCFE" },
      { label: "Salarié non titulaire de l’Etat", value: "SalarieIrcantec" },
      { label: "Clerc de notaire", value: "Crpcen" },
      { label: "Fonctionnaire", value: "FonctionPublique" },
      { label: "Expert comptable salarié", value: "CavecSalarie" },
      { label: "Avocat salarié", value: "CnbfSalarie" },
    ],
  },
  {
    label: "Périodes assimilées",
    options: [
      { label: "Assurance vieillesse des parents au foyer", value: "Avpf" },
      { label: "Chômage et assimilé", value: "Chomage" },
      { label: "Maladie", value: "Maladie" },
      { label: "Maternité", value: "Maternite" },
      { label: "Invalidité", value: "Invalidite" },
      { label: "Militaire, guerre", value: "Militaire" },
      { label: "Trimestres spécifiques", value: "TrimSpecifique" },
    ],
  },
  {
    label: "Activités non salariées",
    options: [
      { label: "Agent général", value: "Cavamac" },
      { label: "Artisan", value: "RsiArtisan" },
      { label: "Avocat", value: "Cnbf" },
      { label: "Auxiliaire médical", value: "Carpimko" },
      { label: "Chirurgien-dentiste", value: "Carcdsf" },
      { label: "Commerçant", value: "RsiCommercant" },
      { label: "Expert-comptable", value: "Cavec" },
      { label: "Exploitant agricole", value: "Msa" },
      { label: "Ingénieur conseil", value: "Cipav" },
      { label: "Médecin", value: "Carmf" },
      { label: "Notaire", value: "Crn" },
      { label: "Officier ministériel, public ou judiciaire", value: "Cavom" },
      { label: "Sage-femme", value: "Carcdsf2" },
      { label: "Pharmacien", value: "Cavp" },
      { label: "Vétérinaire", value: "Carpv" },
    ],
  },
  {
    label: "Micro-entreprise",
    options: [
      { label: "Activité commerciale", value: "MicroRSI" },
      { label: "Prestations de services (BIC)", value: "MicroBIC" },
      { label: "Prestations de services (BNC)", value: "MicroBNC" },
      { label: "Professions libérales réglementées ", value: "MicroCipav" },
    ],
  },
];
export const InitialListeActiviteRetraite2 = [
  // UTILE POU RECHERCHE LE LABEL LORS DE LA MODIF D'UNE PERIODE
  { label: "Activité salariée", value: "ActiviteSalarie" },
  { label: "Salarié non cadre du secteur privé", value: "SalarieNonCadre" },
  { label: "Salarié cadre du secteur privé", value: "SalarieCadre" },

  {
    label: "Salarié non cadre du secteur agricole",
    value: "SalarieNonCadreAgricole",
  },
  { label: "Salarié cadre du secteur agricole", value: "SalarieCadreAgricole" },
  { label: "Salarié non cadre cotisant CFE", value: "SalarieNonCadreCFE" },
  { label: "Salarié cadre cotisant CFE", value: "SalarieCadreCFE" },
  { label: "Salarié non titulaire de l’Etat", value: "SalarieIrcantec" },
  { label: " Clerc de notaire", value: "Crpcen" },
  { label: "Fonctionnaire", value: "FonctionPublique" },
  { label: "Expert comptable salarié", value: "CavecSalarie" },
  { label: "Avocat salarié", value: "CnbfSalarie" },
  { label: "Assurance vieillesse des parents au foyer", value: "Avpf" },
  { label: "Chômage et assimilé", value: "Chomage" },
  { label: "Maladie", value: "Maladie" },
  { label: "Maternité", value: "Maternite" },
  { label: "Invalidité", value: "Invalidite" },
  { label: "Militaire, guerre", value: "Militaire" },
  { label: "Trimestres spécifiques", value: "TrimSpecifique" },
  { label: "Agent général", value: "Cavamac" },
  { label: "Artisan", value: "RsiArtisan" },
  { label: "Avocat", value: "Cnbf" },
  { label: "Auxiliaire médical", value: "Carpimko" },
  { label: "Chirurgien-dentiste", value: "Carcdsf" },
  { label: "Commerçant", value: "RsiCommercant" },
  { label: "Expert-comptable", value: "Cavec" },
  { label: "Exploitant agricole", value: "Msa" },
  { label: "Ingénieur conseil", value: "Cipav" },
  { label: "Officier ministériel, public ou judiciaire", value: "Cavom" },
  { label: "Médecin", value: "Carmf" },
  { label: "Notaire", value: "Crn" },
  { label: "Pharmacien", value: "Cavp" },
  { label: "Sage-femme", value: "Carcdsf2" },
  { label: "Vétérinaire", value: "Carpv" },
  { label: "Activité commerciale", value: "MicroRSI" },
  { label: "Prestations de services (BIC)", value: "MicroBIC" },
  { label: "Prestations de services (BNC)", value: "MicroBNC" },
  { label: "Professions libérales réglementées ", value: "MicroCipav" },
];
export const CorrespondanceActivite = {
  ActiviteSalarie: "Activité salariée",
  SalarieNonCadre: "Salarié non cadre du  secteur privé",
  SalarieCadre: "Salarié cadre du  secteur privé",
  SalarieNonCadreCFE: "Salarié non cadre cotisant CFE",
  SalarieCadreCFE: "Salarié cadre cotisant CFE",
  SalarieNonCadreAgricole: "Salarié non cadre du secteur agricole",
  SalarieCadreAgricole: "Salarié cadre du secteur agricole",
  SalarieIrcantec: "Salarié non titulaire de l’Etat",
  CavecSalarie: "Expert comptable salarié",
  CnbfSalarie: "Avocat salarié",
  FonctionPublique: "Fonction publique",
  Avpf: "Assurance vieillesse des parents au foyer",
  Chomage: "Chômage et assimilé",
  Maladie: "Maladie",
  Maternite: "Maternité",
  Invalidite: "Invalidité",
  Militaire: "Militaire, guerre",
  TrimSpecifique: "Trimestres spécifiques",
  RsiArtisan: "Artisan",
  Cnbf: "Avocat",
  Carpimko: "Auxiliaire médical",
  Carcdsf: "Chirurgien-dentiste",
  RsiCommercant: "Commerçant",
  MicroRSI: "Micro-entrepreneur ",
  MicroBIC: "Micro-entrepreneur ",
  MicroBNC: "Micro-entrepreneur ",
  MicroCipav: "Micro-entrepreneur ",
  Cavec: "Expert-comptable",
  Cavamac: "Agent général",
  Crn: "Notaire",
  Msa: "Exploitant agricole",
  Cipav: "Ingénieur conseil",
  Cavom: "Officier min., pub. ou judi.",
  Carmf: "Médecin",
  Cavp: "Pharmacien",
  Carcdsf2: "Sage-femme",
  Carpv: "Vétérinaire",
  Crpcen: "Clerc de notaire",
};
export const RemExpertPeriodeRetraite = {
  TotalTrimestre: 0,
  AgeDepartRetraite: "",
  DisplayRetraiteProgressive: false,
  DisplayRetraiteProgressiveConjoint: false,
  TotalTrimestreConjoint: 0,
  AgeDepartRetraiteConjoint: "",
  IsGoodDebutPeriode: "Oui",
  RetraiteDebutActivite: "",
  PremierePeriodeManuelle: true,
  IsGoodFinPeriode: "Oui",
  ValidatePeriode: false,
  OpenAlertDialogPeriodeRetraite: false,
  RetraiteFinActivite: "",
  ListeActiviteRetraite: InitialListeActiviteRetraite,
  RetraiteActivite: "SalarieNonCadre",
  RetraiteActiviteLabel: "Salarié non cadre du secteur privé",
  DateDepartRetraite: false,
  DateDepartRetraiteAffichage: false,
  DateDepartRetraiteAffichageConjoint: false,
  CheckboxDepartRetraite: true,
  Carriere: [],
  CarriereConjoint: [],
  IdPeriodeRetraite: "",
  DetailRetraitePasse: {},
  DetailRetraiteFutur: {},
  DisplayDeplafondPass: false,
  DisplayTrimestre: true,
  AllCheckboxTrimestre: false,
  TypeEvolution: "EvolutionPlat",
  MontantEvolution: 0,

  CFEAgircArrco: "Oui",
  ParamCotisations: {
    AgircArrcoT1Sal: RemExpertEnvironnementSocial.AgircArrcoT1Sal,
    AgircArrcoT1Pat: RemExpertEnvironnementSocial.AgircArrcoT1Pat,
    AgircArrcoT2Sal: RemExpertEnvironnementSocial.AgircArrcoT2Sal,
    AgircArrcoT2Pat: RemExpertEnvironnementSocial.AgircArrcoT2Pat,
    IrcantecT1Sal: 2.24,
    IrcantecT1Pat: 3.36,
    IrcantecT2Sal: 5.56,
    IrcantecT2Pat: 10.04,
    classe_retraiteCarpv: "SS1",
    ClasseRetr: "NC",
    ClasseRetraiteCrn: "NC",
    TabMoyenneProduitEtudeCrn: {},
    TauxCpam: 100,
    CrnSermentAvt2014: false,
    CrnColmarMetz: false,
    Conventionne: "oui",
    BiologisteConventionne: "oui",
    CotisationCapi: "classe3",
    ConventionneCarmf: "non",
    ClasseRetraiteCnbf: "C1",
    ClasseRetraiteCnbfAvant2021: "C1",
    StatutIrcantec: "1_100",
    OptionTrimSpecifique: "option1",
    TypeTrimSpecifique: "assimile",
    CavecSalarie: "C",
    ChomageAgirArrco: "Non",
    ChomageSJR: 0,
    Ravgdt: "Non",
    RavgdtRemises: 0,
    MaladieInvalAgircArrco: "Non",
    MaladieInvalPoints: 0,
    TabCommissionBruteCavamac: {},
  },
  TauxRendementCapi: 1.8,
  TauxConversionCapi: 3.13,
  MoyenneProduitCrn: 0,
  CategorieFonctionPublique: "sedentaire",
  LimiteAgePerso: false,
  LimiteAgeAgePerso: "",
  LimiteAgeMoisPerso: "",
  Bonification: 0,
  MDA: 0,
  IndiceMajorePerso: false,
  IndiceMajoreValeur: "",
  AssietteRafp: "pourcentage",
  TauxPrimeRAFP: 0.0,
  MontantPrimeRAFP: 0,
  TauxRendementCapiConjoint: 1.8,
  TauxConversionCapiConjoint: 3.13,
  CategorieFonctionPubliqueConjoint: "sedentaire",
  LimiteAgePersoConjoint: false,
  LimiteAgeAgePersoConjoint: "",
  LimiteAgeMoisPersoConjoint: "",
  BonificationConjoint: 0,
  MDAConjoint: 0,
  IndiceMajorePersoConjoint: false,
  IndiceMajoreValeurConjoint: "",
  AssietteRafpConjoint: "pourcentage",
  TauxPrimeRAFPConjoint: 0.0,
  MontantPrimeRAFPConjoint: 0,
  RachatTrimRU: 0,
  RachatOptionRU: "option1",
  RachatTrimCNAVPL: 0,
  RachatOptionCNAVPL: "option1",
  RachatRevenus: 0,
  RachatTMI: 0,
  RachatTMIRetraite: 0,
  RachatTrimRUConjoint: 0,
  RachatOptionRUConjoint: "option1",
  RachatTrimCNAVPLConjoint: 0,
  RachatOptionCNAVPLConjoint: "option1",
  RachatRevenusConjoint: 0,
  RachatTMIConjoint: 0,
  RachatTMIRetraiteConjoint: 0,
  RetraiteProgDateDebut: "",
  RetraiteProgDateDebutSaisi: false,
  RetraiteProgDateDebutConjoint: "",
  RetraiteProgDateDebutSasisiConjoint: false,
  RetraiteProgDateFin: "",
  RetraiteProgDateFinSaisi: false,
  RetraiteProgDateFinConjoint: "",
  RetraiteProgDateFinSaisiConjoint: false,
  RetraiteProgressiveRevenus: 0,
  RetraiteProgressiveRevenusConjoint: 0,
  RetraiteProgressiveTauxActivite: 100,
  RetraiteProgressiveTauxActiviteConjoint: 100,
  RetraiteProgressiveBaseTempsPlein: "non",
  RetraiteProgressiveBaseTempsPleinConjoint: 100,
  RetraiteProgressiveMoyenneRevenus: 0,
  RetraiteProgressiveMoyenneRevenusConjoint: 0,
  RetraiteProgressiveIFCFin: 0,
  RetraiteProgressiveIFCFinConjoint: 0,
  
  CumulEmploiAlertLURA: false,
  CumulEmploisIdsLURA: [],
  CumulEmploiCarriere: [],
  CumulEmploiResult:[],
  CumulEmploiAgeDepartRetraite: "",
  CumulEmploiChoixDepartRetraite: "",
  CumulEmploiDateDepartRetraite: false,
  CumulEmploiDateDepartRetraiteAffichage: false,
  CumulEmploiLiquidationAgircTC: true,
  CumulEmploiPensionRenteDateCER: 0,

  CumulEmploiAlertLURAConjoint: false,
  CumulEmploisIdsLURAConjoint: [],
  CumulEmploiCarriereConjoint: [],
  CumulEmploiResultConjoint:[],
  CumulEmploiAgeDepartRetraiteConjoint: "",
  CumulEmploiChoixDepartRetraiteConjoint: "",
  CumulEmploiDateDepartRetraiteConjoint: false,
  CumulEmploiDateDepartRetraiteAffichageConjoint: false,
  CumulEmploiLiquidationAgircTCConjoint: true,
  CumulEmploiPensionRenteDateCERConjoint: 0,
};


function InitialAnneeFinSelect() {
  const dt = new Date();

  return dt.getFullYear() - 2;
}

export const RemExpertTableauPoints = {
  RetraiteBaseCnavpl: 0.0,
  AnneeFinCnavpl: InitialAnneeFinSelect(),
  RetraiteCompFonctionPublique: 0.0,
  AnneeFinFonctionPubliqueComp: InitialAnneeFinSelect(),
  RetraiteCompIrcantec: 0.0,
  AnneeFinIrcantec: InitialAnneeFinSelect(),
  RetraiteProportionnelleMsa: 0.0,
  AnneeFinMsaProp: InitialAnneeFinSelect(),
  RetraiteCompMsa: 0.0,
  AnneeFinMsaComp: InitialAnneeFinSelect(),
  RetraiteCompCarcdsf: 0.0,
  AnneeFinCarcdsfComp: InitialAnneeFinSelect(),
  RetraiteCompCarcdsf2: 0.0,
  AnneeFinCarcdsf2Comp: InitialAnneeFinSelect(),
  RetraiteCompCarmf: 0.0,
  AnneeFinCarmfComp: InitialAnneeFinSelect(),
  RetraiteCompCarpimko: 0.0,
  AnneeFinCarpimkoComp: InitialAnneeFinSelect(),
  RetraiteCompCarpv: 0.0,
  AnneeFinCarpvComp: InitialAnneeFinSelect(),
  RetraiteCompCavpCapi: 0.0,
  TrimestreCompCavp: 0.0,
  AnneeFinCavpComp: InitialAnneeFinSelect(),
  RetraiteCompCipav: 0.0,
  AnneeFinCipavComp: InitialAnneeFinSelect(),
  RetraiteCompRavgdt: 0.0,
  AnneeFinRavgdtComp: InitialAnneeFinSelect(),
  RetraiteCompCavom: 0.0,
  AnneeFinCavomComp: InitialAnneeFinSelect(),
  RetraiteCompCnbf: 0.0,
  AnneeFinCnbfComp: InitialAnneeFinSelect(),
  AnneeFinArrco: InitialAnneeFinSelect(),
  RetraiteCompArrcoAvant1999: 0.0,
  TauxMajoEnfantAvant1999: 0.0,
  RetraiteCompArrco19992011: 0.0,
  RetraiteCompArrcoApres2012: 0.0,
  RetraiteCompArrco: 0.0,
  AnneeFinAgircArrco: InitialAnneeFinSelect(),
  RetraiteCompAgircArrco: 0.0,
  AnneeFinAgircTB: InitialAnneeFinSelect(),
  RetraiteCompAgircTBAvant2012: 0.0,
  RetraiteCompAgircTBApres2012: 0.0,
  RetraiteCompAgircTB: 0.0,
  AnneeFinAgircTC: InitialAnneeFinSelect(),
  RetraiteCompAgircTCAvant2012: 0.0,
  RetraiteCompAgircTC20122015: 0.0,
  RetraiteCompAgircTC2016: 0.0,
  RetraiteCompAgircTC: 0.0,
  AnneeFinCavecComp: InitialAnneeFinSelect(),
  RetraiteCompCavecAvant2009: 0.0,
  RetraiteCompCavecApres2009: 0.0,
  RetraiteCompCavec: 0.0,
  RetraiteSuppCarmf: 0.0,
  AnneeFinCarmfSupp: InitialAnneeFinSelect(),
  RetraiteSuppCarcdsf: 0.0,
  RetraiteSuppCarcdsfAvant1995: 0.0,
  RetraiteSuppCarcdsf19952005: 0.0,
  RetraiteSuppCarcdsfApres2006: 0.0,
  AnneeFinCarcdsfSupp: InitialAnneeFinSelect(),
  RetraiteSuppCarcdsf2: 0.0,
  RetraiteSuppCarcdsf2Avant1995: 0.0,
  RetraiteSuppCarcdsf219952005: 0.0,
  RetraiteSuppCarcdsf2Apres2006: 0.0,
  AnneeFinCarcdsf2Supp: InitialAnneeFinSelect(),
  RetraiteCompCrnSectionB: 0.0,
  AnneeFinCrnSectionB: InitialAnneeFinSelect(),
  RetraiteCompCrnSectionC: 0.0,
  AnneeFinCrnSectionC: InitialAnneeFinSelect(),
  RetraiteCompCavamac: 0.0,
  AnneeFinCavamacComp: InitialAnneeFinSelect(),

  RetraiteSuppCarpimko19601975: 0.0,
  RetraiteSuppCarpimko19761987: 0.0,
  RetraiteSuppCarpimko19881997: 0.0,
  RetraiteSuppCarpimko19982005: 0.0,
  RetraiteSuppCarpimkoApres2005: 0.0,
  RetraiteSuppCarpimko: 0.0,
  AnneeFinCarpimkoSupp: InitialAnneeFinSelect(),

  RetraiteSuppCavp19771981: 0.0,
  RetraiteSuppCavp19821986: 0.0,
  RetraiteSuppCavp19871991: 0.0,
  RetraiteSuppCavp19921996: 0.0,
  RetraiteSuppCavp19972001: 0.0,
  RetraiteSuppCavp20022005: 0.0,
  RetraiteSuppCavpApres2006: 0.0,
  RetraiteSuppCavp: 0.0,
  AnneeFinCavpSupp: InitialAnneeFinSelect(),

  RetraiteTotalRCI: 0.0,
  RetraiteCompRsiArtisanAvant1979: 0.0,
  RetraiteCompRsiArtisanAvant1997: 0.0,
  RetraiteCompRsiArtisanApres1997: 0.0,
  RetraiteCompRsiArtisan: 0.0,
  PointRetraiteCompRsiCommercantAvant2013: 0.0,
  RetraiteCompRCI: 0.0,
  AnneeFinRsiComp: InitialAnneeFinSelect(),
};

export const RemExpertMajorationEnfantRetraite = {
  CarriereMajorationEnfant: 0,
  TrimestreBonificationEnfantFonctionPublique: 0,
  TrimestreBonificationEnfantSaisiFonctionPublique: false,
  CarriereMajorationEnfantSaisi: false,
  CarriereMajorationEnfantFonctionPublique: 0,
  CarriereMajorationEnfantSaisiFonctionPublique: false,
  CarriereMajorationEnfantCrpcen: 0,
  CarriereMajorationEnfantSaisiCrpcen: false,
  CarriereMajorationEnfantCrpcenApres2006: 0,
  CarriereMajorationEnfantSaisiCrpcenApres2006: false,
  CarriereMajorationEnfantConjoint: 0,
  TrimestreBonificationEnfantFonctionPubliqueConjoint: 0,
  TrimestreBonificationEnfantSaisiFonctionPubliqueConjoint: false,
  CarriereMajorationEnfantSaisiConjoint: false,
  CarriereMajorationEnfantFonctionPubliquConjointe: 0,
  CarriereMajorationEnfantSaisiFonctionPubliqueConjoint: false,
  CarriereMajorationEnfantCrpcenConjoint: 0,
  CarriereMajorationEnfantSaisiCrpcenConjoint: false,
  CarriereMajorationEnfantCrpcenApres2006Conjoint: 0,
  CarriereMajorationEnfantSaisiCrpcenApres2006Conjoint: false,
  UnlockMajoEnfantAgircArrco: false,
  UnlockMajoEnfantAgircArrcoConjoint: false,
};
export const RemExpertParametreRetraite = {
  ReformeRetraite: false,
  ReformeRetraiteConjoint: false,
  CarriereSalaireReference: 0,
  CarriereSalaireReferenceConjoint: 0,
  CarriereSalaireReferenceCalcule: 0,
  CarriereSalaireReferenceCalculeConjoint: 0,
  CarriereSalaireReferenceSaisi: false,
  CarriereSalaireReferenceSaisiConjoint: false,
  TauxPS: 9.1,
  RegimeAM: "RG",
  TauxPSConjoint: 9.1,
  RegimeAMConjoint: "RG",
  InvaliditeRegimeUnifie:false,
  InvaliditeCnavpl:false,
  InvaliditeFonctionPubliqueBase:false,
  InvaliditeCrpcen:false,
  InvaliditeCnbf:false,
  InvaliditeMsa:false,
  InvaliditeAgircArrco:false,
  InvaliditeIrcantec:false,
  InvaliditeRCI:false,
  InvaliditeRavgdt:false,
  InvaliditeCipav:false,
  InvaliditeCarpv:false,
  InvaliditeCavec:false,
  InvaliditeCavamac:false,
  InvaliditeCrn:false,
  InvaliditeCarpimko:false,
  InvaliditeCarcdsf:false,
  InvaliditeCarcdsf2:false,
  InvaliditeCavom:false,
  InvaliditeCavp:false,
  InvaliditeCnbfComp:false,
  InvaliditeCarcdsfAsv:false,
  InvaliditeCarcdsf2Asv:false,
  InvaliditeCapimkoAsv:false,
  InvaliditeCavpAsv:false,
  InvaliditeRegimeUnifieConjoint:false,
  InvaliditeCnavplConjoint:false,
  InvaliditeFonctionPubliqueBaseConjoint:false,
  InvaliditeCrpcenConjoint:false,
  InvaliditeCnbfConjoint:false,
  InvaliditeMsaConjoint:false,
  InvaliditeAgircArrcoConjoint:false,
  InvaliditeIrcantecConjoint:false,
  InvaliditeRCIConjoint:false,
  InvaliditeRavgdtConjoint:false,
  InvaliditeCipavConjoint:false,
  InvaliditeCarpvConjoint:false,
  InvaliditeCavecConjoint:false,
  InvaliditeCavamacConjoint:false,
  InvaliditeCrnConjoint:false,
  InvaliditeCarpimkoConjoint:false,
  InvaliditeCarcdsfConjoint:false,
  InvaliditeCarcdsf2Conjoint:false,
  InvaliditeCavomConjoint:false,
  InvaliditeCavpConjoint:false,
  InvaliditeCnbfCompConjoint:false,
  InvaliditeCarcdsfAsvConjoint:false,
  InvaliditeCarcdsf2AsvConjoint:false,
  InvaliditeCapimkoAsvConjoint:false,
  InvaliditeCavpAsvConjoint:false,
};

export const RemExpertListeContratRetraite = {
  ListeContrat: [],
  ListeContratConjoint: [],
};
export const RemExpertContratRetraite = {
  IdContratRetraite: "",
  FiscaliteContratRetraite: "Per",
  FiscaliteContratPer: "Madelin",
  ForfaitSocialArticle83: 16,
  CompagnieContratRetraite: "",
  StatutContratRetraite: "En cours",
  CotisationContratRetraite: {},
  SalaireBrutContratRetraite: 0,
  SalaireBrutContratRetraiteConjoint: 0,
  SalaireBrutContratPrevoyance: 0,
  TauxIndexationContratRetraite: 0.0,
  PrimePerTP: "Perso",
  PrimePerp: "Perso",
  PayeurPrimeRetraite: "Entreprise",
  DeductionFiscaleVersementContratRetraite: "oui",
  PeriodiciteContratRetraite: "annuelle",
  AssietteCotisation83: "AssietteSalaireBrut",
  taux_retraite_trancheA_salarie83: 0.0,
  taux_retraite_trancheA_patronale83: 0.0,
  taux_retraite_trancheB_salarie83: 0.0,
  taux_retraite_trancheB_patronale83: 0.0,
  taux_retraite_trancheC_salarie83: 0.0,
  taux_retraite_trancheC_patronale83: 0.0,
  taux_retraite_trancheD_salarie83: 0.0,
  taux_retraite_trancheD_patronale83: 0.0,
  CSGPERCOContratRetraite: "non",
  InteressementContratRetraite: 0,
  ParticipationContratRetraite: 0,
  AbondementContratRetraite: 0,
  VolontaireContratRetraite: 0,
  EpargneAcquiseContratRetraite: 0,
  EpargneAcquiseVersementContratRetraite: 0,
  EpargneAcquiseProduitContratRetraite: 0,
  DureeFractionnementContratRetraite: 20,
  RevalorisationCapitalContratRetraite: 0,
  PartCapitalPERContratRetraite: 0.0,
  RendementContratRetraite: 2.0,
  FraisVersementContratRetraite: 4.5,
  SetTauxConversionContratRetraite: false,
  TauxConversionContratRetraite: 2.0,
  FraisArrerageContratRetraite: 0.0,
  SortieContratRetraite: "RenteSimple",
  ReversionContratRetraite: "non",
  TauxReversionContratRetraite: 0.0,
  VersementInitialPerpContratRetraite: 0,
  VersementProgrammePerpContratRetraite: 0,
  SaisieContratRetraite: "automatique",
  TableauSaisieManuelleRente: [],
};
//LES PARAMS PERP SONT A PART POUR NE PAS LES REINITIALISER LORS DE L'AJOUT D'UN NOUVEAU CONTRAT
export const ParamPerpContratRetraite = {
  RevenusNetsMoins1PerpContratRetraite: 0,
  AbondementPercoMoins1PerpContratRetraite: 0,
  CotisationMadelinMoins1PerpContratRetraite: 0,
  Cotisation83Moins1PerpContratRetraite: 0,
  RevenusNetsMoins1PerpContratRetraiteConjoint: 0,
  AbondementPercoMoins1PerpContratRetraiteConjoint: 0,
  CotisationMadelinMoins1PerpContratRetraiteConjoint: 0,
  Cotisation83Moins1PerpContratRetraiteConjoint: 0,
};

export const RemExpress = {
  RemExpressTypeCalcul: "",
  RemExpressCoutEntreprise: 0,
  RemExpressCotisations: 0,
  RemExpressCotisationsPatronale: 0,
  RemExpressCotisationsSalariale: 0,
  RemExpressCoutDividende: 0,
  RemExpressDividendeBrut: 0,
  RemExpressDividendeBrutAutres: 0,
  RemExpressCoutRemuneration: 0,
  RemExpressRemBrute: 0,
  RemExpressRemNette: 0,
  RemExpressRemImposable: 0,
  RemExpressResAvtIs: 0,
  RemExpressImpotSoc: 0,
  RemExpressReserve: 0,
  RemExpressDivAutres: 0,

  RemExpressRevPercus: 0,
  RemExpressRevenusFoyer: 0,
  RemExpressIRTotal: 0,
  RemExpressCotFacPerso: 0,
  RemExpressRevDispo: 0,
};

export const RemExpertListeOptimisation = {
  ListeOptimisation: [],
  LibelleOptimisation: "",
  IdOptimisation: "",
};
export const RemExpressListeOptimisation = {
  RemExpressListeOptimisation: [],
};

export const PrevTns = {
  AffichContratPreco: 1,
  PrevTNSPreco1ContratEnregistre: [],
  PrevTNSPreco2ContratEnregistre: [],
  PrevTNSPreco3ContratEnregistre: [],
  PrevTNSPreco1ContratFraisPro: [],
  PrevTNSPreco2ContratFraisPro: [],
  PrevTNSPreco3ContratFraisPro: [],
};

export const PrevTns2 = {
  PrevTns2Nationalite: "FRANCAISE",
  PrevTns2VilleResidence: "",
  PrevTns2CodePostal: "",
  PrevTns2Fumeur: "Non",
  PrevTns2TableauEnfant: [],
  PrevTns2ClassePrevoyanceCavec: "1",
  PrevTns2ModeExercice: "Nom propre",
  PrevTns2FormeJuridique: "Entreprise individuelle",
  PrevTns2Profession: "",
  PrevTns2SecteurActivite: "0",
  PrevTns2DateCreation: "",
  PrevTns2Siret: "",
  PrevTns2CodePostalEntreprise: "",
  PrevTns2VilleEntreprise: "",
  PrevTns2ListeVille: [],
  PrevTns2Createur: "Non",
  PrevTns2Repreneur: "Non",
  PrevTns2NombreSalarie: 0,
  PrevTns2KmParcouru: 0,
  PrevTns2RecueilBesoin: {
    AssietteGarantie: 0,
    DateEffet: "",
    IncapCouverture: "Oui",
    IncapMontant: 0,
    IncapFranchise: "30/3/3",
    DecesCouverture: "Oui",
    DecesMontant: 0,
    DecesDoubleAccident: "Non",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "01011970",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: 0,
    InvalDeclenchement: "33",
  },
  PrevTns2ContratLourmel: {
    Link: "https://wwwsesame-p1.cbp-solutions.fr/toa/public/secure/ui/p/SOFR",
    Login: "",
    Password: "",
    NomCompagnie: "LOURMEL",
    NomContrat: "LOURMEL PREVOYANCE INDIVIDUEL",
    StatutPro: "",
    Profession: "",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "15/3/3",
    DecesCouverture: "Oui",
    DecesMontant: "",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "33",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Non",
    Commission: "P20/20",
  },
  PrevTns2ContratAprilGMPL: {
    Link: "https://www.april-on.fr/fr/login",
    Login: "",
    Password: "",
    NomCompagnie: "APRIL",
    NomContrat: "APRIL GM/PL",
    Profession: "",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "15/3/0",
    DecesCouverture: "Oui",
    DecesMontant: "200+50",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "15",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Non",
    OptionFraisObseque: "Non",
    Commission: "15/15",
  },
  PrevTns2ContratAprilPrevoyancePRO: {
    Link: "https://www.april-on.fr/fr/login",
    Login: "",
    Password: "",
    NomCompagnie: "APRIL",
    NomContrat: "APRIL PREVOYANCE PRO",
    Profession: "",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapTaux: "",
    IncapFranchise: "15/3/0",
    DecesCouverture: "Oui",
    DecesMontant: "",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DecesRenteEducation: "",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalTaux: "",
    InvalDeclenchement: "15",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Non",
    OptionFraisObseque: "Non",
    Commission: "30/10",
  },
  PrevTns2ContratVidens: {
    Link: "https://sofraco.cegema.com",
    Login: "",
    Password: "",
    NomCompagnie: "VIDENS",
    NomContrat: "VIDENS PRO",
    Profession: "",
    GerantSociete: "Non",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "15/15/15",
    IncapFranchiseOption: {
      Accident: "Non",
      Hospitalisation: "Non",
      Maladie: "Non",
    },
    DecesCouverture: "Oui",
    DecesMontant: "300+100",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "Croise15",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Oui",
    OptionConfortDos: "Oui",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Non",
    Commission: "",
  },
  PrevTns2ContratSpvie: {
    Link: "https://luca.spvie.com/",
    Login: "",
    Password: "",
    NomCompagnie: "SPVIE",
    NomContrat: "SPVIE ALPHA TNS",
    Profession: "",
    DisplayPrecision: false,
    CategorieTNS: "",
    IncapMode: "-",
    IncapCouverture: "Oui",
    Assiette: "",
    Pass: "",
    IncapMontant: 100,
    IncapFranchise: "15/15/15",
    IncapRachatFranchise: "Non",
    DecesCouverture: "Oui",
    DecesMontant: "300+50",
    DecesDoubleAccident: "Non",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "33",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Oui",
    OptionFraisObsequeValue: "",
    Commission: 0.15,
  },
  PrevTns2ContratEntoria: {
    Link: "https://espacecourtier.cipres.fr/",
    Login: "",
    Password: "",
    NomCompagnie: "ENTORIA",
    // NomContrat: "SPVIE ALPHA TNS",
    // Profession: "",
    // DisplayPrecision: false,
    // CategorieTNS: "",
    // IncapMode: "-",
    // IncapCouverture: "Oui",
    // Assiette: "",
    // Pass: "",
    // IncapMontant: 100,
    // IncapFranchise: "15/15/15",
    // IncapRachatFranchise: "Non",
    // DecesCouverture: "Oui",
    // DecesMontant: "300+50",
    // DecesDoubleAccident: "Non",
    // DecesRenteConjoint: "Non",
    // DateNaissanceConjoint: "",
    // DecesRenteEducation: "Non",
    // InvalCouverture: "Oui",
    // InvalMontant: "",
    // InvalDeclenchement: "33",
    // InvalCapitalInvalidite: "",
    // OptionCapitalMaladieGrave: "Non",
    // OptionConfortPsy: "Non",
    // OptionConfortDos: "Non",
    // OptionExonerationCotisation: "Oui",
    // OptionFraisObseque: "Oui",
    // OptionFraisObsequeValue: "",
    // Commission: 0.15,
  },
  PrevTns2ContratHodeva: {
    Link: "https://extranet.hodeva.com",
    Login: "",
    Password: "",
    NomCompagnie: "HODEVA",
    NomContrat: "HODEVA PREVOYANCE TNS",
    Profession: "",
    GerantMajoritaire: "Non",
    StatutPro: "",
    OptionForfaitairePure: "Non",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "30/3/3",
    DecesCouverture: "Oui",
    DecesMontant: "",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "33",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Oui",
    OptionFraisObsequeValue: "",
    Commission: "15/15",
  },
  PrevTns2ContratAlptis: {
    Link: "https://pro.alptis.org/",
    Login: "",
    Password: "",
    NomCompagnie: "ALPTIS",
    NomContrat: "ALPTIS SPI ART COM",
    Profession: "",
    Manutention: "Non",
    TravailHauteur: "Non",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "30/3/3/0",
    DecesCouverture: "Oui",
    DecesMontant: "300+100",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "33",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Oui",
    PASS: 0,
    Commission: "Standard",
  },
  PrevTns2ContratSwisslifeSLPI: {
    Link: "https://www.swisslifeone.fr/index-swisslifeOne.html",
    Login: "",
    Password: "",
    NomCompagnie: "SWISSLIFE",
    NomContrat: "SWISSLIFE SLPI",
    Profession: "",
    GerantMajoritaire: "Non",
    IncapMode: "-",
    IncapCouverture: "Oui",
    DisableIncap: false,
    IncapMontant: "",
    IncapTaux: "",
    IncapTaux1: "",
    IncapTaux2: "",
    DisablePeriode1: false,
    IncapFranchise: "00 / 03 / 07",
    DecesCouverture: "Oui",
    DecesMontant: "",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DateNaissanceConjoint: "",
    DecesRenteEducation: "Non",
    DisableInval: false,
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "Croise",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Oui",
    OptionExonerationCotisation: "Oui",
    OptionFraisObseque: "Oui",
    OptionFraisObsequeValue: "",
    // Commission: "15/15"
  },
  PrevTns2ContratAvivaSPP: {
    Link: "https://www.abeille-vie-partenaires.fr/",
    Login: "",
    Password: "",
    StatutPro: "",
    NomCompagnie: "AVIVA",
    NomContrat: "AVIVA SPP",
    Profession: "",
    IncapMode: "-",
    IncapCouverture: "Oui",
    IncapMontant: "",
    IncapFranchise: "30/3/3",
    DecesCouverture: "Oui",
    DecesMontant: "200",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "Non",
    DecesRenteEducation: "Non",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalDeclenchement: "20",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Non",
    OptionFraisObseque: "Non",
    Commission: "TN",
  },
  PrevTns2ContratAvivaSenseo: {
    Link: "https://www.abeille-vie-partenaires.fr/",
    Login: "",
    Password: "",
    NomCompagnie: "AVIVA",
    NomContrat: "AVIVA SENSEO",
    Profession: "",
    StatutPro1: "",
    StatutPro2: "",
    StatutPro3: "",
    StatutPro4: "",
    IncapMode: "Indemnitaire",
    IncapCouverture: "Oui",
    DisableModeIndemnitaire: false,
    IncapMontant: "",
    IncapTaux: "",
    IncapFranchise: "030/003/003",
    IncapFranchiseLongue: "",
    IncapFranchiseCourte: "",
    DecesCouverture: "Oui",
    DecesMontant: "",
    DecesDoubleAccident: "Oui",
    DecesRenteConjoint: "",
    DateNaissanceConjoint: "",
    DisableRenteConjoint: false,
    DecesRenteEducation: "Non",
    DecesMontantRenteEducation: "",
    InvalCouverture: "Oui",
    InvalMontant: "",
    InvalTaux: "",
    InvalDeclenchement: "20",
    InvalCapitalInvalidite: "",
    OptionCapitalMaladieGrave: "Non",
    OptionConfortPsy: "Non",
    OptionConfortDos: "Non",
    OptionExonerationCotisation: "Non",
    OptionFraisObseque: "Non",
    Commission: "TN",
  },
  PrevTns2ValidateFormSituationPerso: [
    { Libelle: "Civilite", Saisie: false },
    { Libelle: "Nom", Saisie: false },
    { Libelle: "Prenom", Saisie: false },
    { Libelle: "DateNaissance", Saisie: false },
    { Libelle: "Nationalite", Saisie: false },
    { Libelle: "CodePostal", Saisie: false },
    { Libelle: "Ville", Saisie: false },
    { Libelle: "Fumeur", Saisie: false },
    { Libelle: "StatutMarital", Saisie: false },
    { Libelle: "TableauEnfant", Saisie: false },
  ],
  PrevTns2ValidateFormSituationPro: [
    { Libelle: "Caisse", Saisie: false },
    { Libelle: "Profession", Saisie: false },
    { Libelle: "SecteurActivite", Saisie: false },
    { Libelle: "ModeExerice", Saisie: false },
    { Libelle: "Siret", Saisie: false },
    { Libelle: "IdentiteEntreprise", Saisie: false },
    { Libelle: "DateCreation", Saisie: false },
    { Libelle: "CodePostalEntreprise", Saisie: false },
    { Libelle: "VilleEntreprise", Saisie: false },
    { Libelle: "Remuneration", Saisie: false },
  ],
};

export const CalculatriceAgircArrco = {
  CalculatriceTableauAgircArrco: {},
};

export const DeclaPER = {
  DeclaPERRemunerationImposable: 0,
  DeclaPERRegimeFiscal: "tns",
  DeclaPERAbondementPERCO: 0,
  DeclaPERRetraiteMadelin: 0,
  DeclaPERPrevoyanceMadelin: 0,
  DeclaPERRetraite83: 0,
  DeclaPERRetraite163: 0,
  DeclaPERAssietteDisponible: "exclu",
  DeclaPERResultat: {},
};
export const CorrectionPER = {
  CorrectionPER: {},
  CorrectionPERMutuPlafond: "non",
  CorrectionPERPlafondClient: 0,
  CorrectionPERPlafondConjoint: 0,
};

export const StrategiePER = {
  StrategiePERCaisse: "RsiCommercant",
  StrategiePERClasseRetraiteCavp: "classe3",
  StrategiePERRemuImposable: 0,
  StrategiePERAgeDepartRetraite: 64,
  StrategiePERAMoisDepartRetraite: 0,
  StrategiePERRetaiteROBrute: 0,
  StrategiePERRetaiteRONette: 0,
  StrategiePERChoixStrategie: "rentevscapital",
  StrategiePERChoixSortie: "rente",
  StrategiePERRevenuNetComplementaire: 0,
  StrategiePEREsperanceVie: 90,
  StrategiePERCadreFiscal: "163",
  StrategiePERDisponibleFiscal: 0,
  StrategiePERDisponibleTabPlafond163: {},
  StrategiePERDisponiblMutuPlafond: "Non",
  StrategiePERDisponiblePrevoyanceMadelin: 0,
  StrategiePERDisponibleSanteMadelin: 0,
  StrategiePERDisponibleAbondementPERCO: 0,
  StrategiePERDeductionCotisation: "IR",
  StrategiePERRai: 0,
  StrategiePERRevenuNetActivite: 0,
  StrategiePERRevenuNetRetraite: 0,
  StrategiePERPartFiscaleRetraite: 0,
  StrategiePERSetPartFiscaleRetraite: false,
  StrategiePERContratLibelle: "",
  StrategiePERContratVersementInitial: 0,
  StrategiePERContratVersementProgramme: 0,
  StrategiePERContratTauxIndexation: 0,
  StrategiePERContratFraisVersement: 4.5,
  StrategiePERContratGestion: "Gestion libre",
  StrategiePERContratRendementMoyen: 3,
  StrategiePERContratChoixRente: "Rente simple",
  StrategiePERContratSetTauxConversion: false,
  StrategiePERContratTauxConversion: 2.0,
  StrategiePERContratFraisArrerage: 0,
  StrategiePERContratRevalorisationRente: 2,
  StrategiePERContratDureeFractionnement: 20,
  StrategiePERContratRevalorisationCapital: 2,
  StrategiePERResultat: {},
};
