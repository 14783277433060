import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import { AppContext } from "components/app/RemExprertProvider";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Modal from "components/ui/modal/modal";
import { applyFormat } from "components/ui/ui-helpers";
import ContratFacProModal from "pages/audit complet/simulation/contratfacultatifpro pager";
import RemExpressContratFacProModal from "pages/audit rémunération/contratfacultatifpro pager";
import React, { useContext, useState } from "react";
import { FaCalculator, FaEdit, FaGlobe } from "react-icons/fa";

function RemExpressOptimisationRemunerationTns(props) {
  const { Prenom } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const [modalContrat, setModalContrat] = useState(false);

  return (
    <CardBox className="blockRoundedForce" bpad>
      <table className="simulation">
        <tbody>
          <tr>
            <td className="libelle">Statut social</td>
            <td className="origine">{context.state["PeriodeActuelle"][0]?.Statut}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">{context.state["OptiRemPeriodeActuelle"] !== undefined ? context.state["OptiRemPeriodeActuelle"][0]?.Statut : ""}</td>
            <td className="extra"></td>
            <td className="ecart"></td>
          </tr>
          <tr>
            <td className="libelle">Budget rémunération pour {Prenom}</td>
            <td className="origine">{applyFormat(context.state.RemExpressCoutRemuneration, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                value={context.state["OptiRemCoutRemuneration"]}
                onChange={(e) => {
                  context.handleCalculOptiRem("cout_remuneration", "OptiRemCoutRemuneration", "", ResultatTNS, false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={
                  context.state["OptiRemPeriodeActuelle"][0]?.Statut === "TNS individuel" &&
                  context.state["OptiRemPeriodeActuelle"][0]?.ModeExercice === "IR" &&
                  context.state.OptiRemUnlockCoutEntreprise === false
                }
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemCoutRemuneration"]) - Number(context.state.RemExpressCoutRemuneration))}</td>
          </tr>
          <tr>
            <td className="libelle">Cotisations obligatoires et facultatives</td>
            <td className="origine">{applyFormat(context.state.RemExpressCotisations, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle pr4">
              <Button
                className="editIconOptiRem"
                onClick={() => {
                  setModalContrat(true);
                }}
                icon
                style={{ display: "inline-block" }}
              >
                <FaEdit />
              </Button>
              {applyFormat(context.state["OptiRemCotisations"], "uint", { thousands: " ", decimal: ",", unit: " €" })}
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemCotisations"]) - Number(context.state.RemExpressCotisations))}</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération nette</td>
            <td className="origine">{applyFormat(context.state.RemExpressRemNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                // onKeyDown={(e)=>{context.handleMontantOptiRem("OptiRemRemNette" + CliConj,e)}}
                value={context.state["OptiRemRemNette"]}
                onChange={(e) => {
                  context.handleCalculOptiRem("remuneration_nette", "OptiRemRemNette", "", ResultatTNS, false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={
                  context.state["OptiRemPeriodeActuelle"][0]?.Statut === "TNS individuel" &&
                  context.state["OptiRemPeriodeActuelle"][0]?.ModeExercice === "IR" &&
                  context.state.OptiRemUnlockCoutEntreprise === false
                }
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemRemNette"]) - Number(context.state.RemExpressRemNette))}</td>
          </tr>
          <tr>
            <td className="libelle">Rémunération imposable</td>
            <td className="origine">{applyFormat(context.state.RemExpressRemImposable, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
            <td className="space">&nbsp;</td>
            <td className="nouvelle">
              <TextField
                iconBefore={
                  <Button icon>
                    <FaCalculator />
                  </Button>
                }
                // onKeyDown={(e)=>{context.handleMontantOptiRem("OptiRemRemImposable" + CliConj,e)}}
                value={context.state["OptiRemRemImposable"]}
                onChange={(e) => {
                  context.handleCalculOptiRem("brut_imposable", "OptiRemRemImposable", "", ResultatTNS, false, e);
                }}
                dataType="uint"
                format={{ thousands: " ", unit: " €" }}
                small
                disabled={
                  context.state["OptiRemPeriodeActuelle"][0]?.Statut === "TNS individuel" &&
                  context.state["OptiRemPeriodeActuelle"][0]?.ModeExercice === "IR" &&
                  context.state.OptiRemUnlockCoutEntreprise === false
                }
              />
            </td>
            <td className="extra">&nbsp;</td>
            <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemRemImposable"]) - Number(context.state.RemExpressRemImposable))}</td>
          </tr>
        </tbody>
      </table>

      <Modal
        width="80%"
        height="80%"
        title="Situation professionnelle"
        icon={<FaGlobe />}
        visible={modalContrat}
        onClose={() => {
          context.handleCalculOptiRem("res_avant_is", "OptiRemResAvtIs", "", ResultatTNS, false, context.state["OptiRemResAvtIs"]);
          setModalContrat(false);
        }}
      >
        <RemExpressContratFacProModal
          Outil="OptiRem"
          ClientConjoint={""}
          visible={modalContrat}
          onClose={() => {
            context.handleCalculOptiRem("res_avant_is", "OptiRemResAvtIs", "", ResultatTNS, false, context.state["OptiRemResAvtIs"]);
            setModalContrat(false);
          }}
        />
      </Modal>
    </CardBox>
  );
}
export default RemExpressOptimisationRemunerationTns;
