import "./../audit complet/simulation/simulation.css";
import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaGlobe, FaIdBadge, FaPrint, FaSave, FaUndo } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CheckBox from "components/ui/controls/check-box/check-box";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import Pager from "components/ui/controls/pager/pager";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { caisse } from "components/app/RemExpertIntialState";
import ParamCarcdsf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarcdsf";
import ParamCarmf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarmf";
import ParamCarpimko from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpimko";
import ParamCarpv from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpv";
import ParamCavec from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavec";
import ParamCavp from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavp";
import ParamCnbf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCnbf";
import ParamCrn from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCrn";
import ParamMsa from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamMsa";
import ParamLpaPageModal from "pages/audit complet/situation professionnelle/modules/parametre lpa modal";
import ParamFicheDePaiePageModal from "pages/audit complet/situation professionnelle/modules/parametre fiche de paie modal";
import DetailTNSPageModal from "pages/audit complet/résultat/detail/rémuneration/detailTNSPager";
import DetailSalariePageModal from "pages/audit complet/résultat/detail/rémuneration/detailSalariePager";
import RemExpressOptimisationRemunerationTns from "components/app/AuditRemuneration/Optimisation/RemExpressOptimisationRemunerationTns";
import RemExpressOptimisationRemunerationSalarie from "components/app/AuditRemuneration/Optimisation/RemExpressOptimisationRemunerationSalarie";
import RemExpressOptimisationResAvtIS from "components/app/AuditRemuneration/Optimisation/RemExpressOptimisationResAvtIS";
import RemExpressOptimisationRevenus from "components/app/AuditRemuneration/Optimisation/RemExpressOptimisationRevenus";
import { GestionnaireScenarioPptx } from "components/app/Api/Rapport/ApiRapport";
// import DetailTNSPageModal from "./detail/rémuneration/detailTNSPager";
// import DetailSalariePageModal from "./detail/rémuneration/detailSalariePager";
// import DropDown from "components/ui/controls/drop-down/drop-down";
// import { AuditCompletWord } from "components/app/Api/Rapport/ApiRapport";

const RemExpressOptiRemPage = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [modalOptimum, setModalOptimum] = useState(false);
  const [modalChgtStatut, setModalChgtStatut] = useState(false);
  const Periode = context.state.OptiRemPeriodeActuelle === undefined ? undefined : context.state.OptiRemPeriodeActuelle[0];
  const Prenom = context.state.Prenom;
  const [circularWidth, setCircularWidth] = useState("0px");
  useEffect(() => {
    // context.handleCalculOptiRem("cout_entreprise", "OptiRemCoutEntreprise" + ClientConjoint, ClientConjoint, ResultatTNS, context.state["OptiRemCoutEntreprise" + ClientConjoint]);
  }, []);
  //AVANT
  let cotProductiveRetraiteObligatoireAvant = 0;

  cotProductiveRetraiteObligatoireAvant =
    ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteObligatoire === undefined
      ? 0
      : ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteObligatoire;

  let cotProductiveRetraiteFacultativeAvant = 0;
  cotProductiveRetraiteFacultativeAvant = isNaN(
    Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteFacultative) +
      Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPEEProductif) +
      Number(ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPERCOProductif)
  )
    ? 0
    : ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.RetraiteFacultative +
      ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPEEProductif +
      ResultatTNS.state.RemExpressRemuneration?.InfosRemuneration.Rapport.TotalPERCOProductif;

  //APRES
  let cotProductiveRetraiteObligatoireApres = 0;

  cotProductiveRetraiteObligatoireApres =
    ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration?.Rapport?.RetraiteObligatoire === undefined
      ? 0
      : ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration?.Rapport?.RetraiteObligatoire;

  let cotProductiveRetraiteFacultativeApres = 0;
  cotProductiveRetraiteFacultativeApres = isNaN(
    Number(ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration?.Rapport?.RetraiteFacultative) +
      Number(ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration?.Rapport?.TotalPEEProductif) +
      Number(ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration?.Rapport?.TotalPERCOProductif)
  )
    ? 0
    : ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration.Rapport.RetraiteFacultative +
      ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration.Rapport.TotalPEEProductif +
      ResultatTNS.state.OptiRemRemuneration?.InfosRemuneration.Rapport.TotalPERCOProductif;

  return (
    <>
      <Column fill>
        <Row>
          <Column fill style={{ minWidth: "640px", maxWidth: "820px" }}>
            <CardBox bpad className="light-title blockRoundedForce">
              <table id="simulation" className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">&nbsp;</td>
                    <td className="origine">Situation d'origine</td>
                    <td className="space"></td>
                    <td className="nouvelle" style={{ textAlign: "center" }}>
                      Nouvelle situation
                    </td>
                    <td className="extra">&nbsp;</td>
                    <td className="ecart">Ecart</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">Enveloppe entreprise</td>
                    <td className="origine">{applyFormat(context.state.RemExpressCoutEntreprise, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="space"></td>
                    <td className="nouvelle">
                      {applyFormat(context.state.OptiRemCoutEntreprise, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      {/* <TextField
                            iconBefore={
                              <Button icon>
                                <FaCalculator />
                              </Button>
                            }
                            value={context.state.OptiRemCoutEntreprise}
                            // onKeyUp={(e)=>{context.handleMontantOptiRem("OptiRemCoutEntreprise",e)}}
                            onChange={(e) => {
                              context.handleCalculOptiRem("cout_entreprise", "OptiRemCoutEntreprise", "", ResultatTNS,false, e);
                            }}
                            disabled={!context.state.OptiRemUnlockCoutEntreprise}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            small
                          /> */}
                    </td>
                    <td className="extra">
                      <CheckBox checked={true} unchecked={false} value={context.state.OptiRemUnlockCoutEntreprise} onChange={context.handleCheckChange("OptiRemUnlockCoutEntreprise")} />
                    </td>
                    <td className="ecart">{ApplySignedFormat(Number(context.state.OptiRemCoutEntreprise) - Number(context.state.RemExpressCoutEntreprise))}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
              <RemExpressOptimisationRemunerationTns Prenom={Prenom} />
            ) : (
              <RemExpressOptimisationRemunerationSalarie Prenom={Prenom} />
            )}
            {(Periode?.Statut !== "TNS individuel" || Periode?.ModeExercice === "IS") && <RemExpressOptimisationResAvtIS Periode={context.state["PeriodeActuelle"][0]} Prenom={Prenom} />}

            <RemExpressOptimisationRevenus
              Revenus={context.state.RemExpressRevPercus}
              AutresRevenus={ResultatTNS.state.DetailImpot?.AutresRevenusFoyer}
              IRTotal={ResultatTNS.state.DetailImpot?.IRTotal}
              CotisationsFacPerso={context.state.RemExpressCotFacPerso}
            />
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">Revenu disponible</td>
                    <td className="origine">{applyFormat(context.state.RemExpressRevDispo, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="space"></td>
                    <td className="nouvelle">
                      <TextField
                        iconBefore={
                          <Button icon>
                            <FaCalculator />
                          </Button>
                        }
                        value={context.state.OptiRemRevDispo}
                        // onKeyUp={(e)=>{context.handleMontantOptiRem("OptiRemRevDispo",e)}}
                        onChange={(e) => {
                          context.handleCalculOptiRem("revenu_dispo", "OptiRemRevDispo", "", ResultatTNS, false, e);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        small
                      />
                    </td>
                    <td className="extra"></td>
                    <td className="ecart"> {ApplySignedFormat(Number(context.state.OptiRemRevDispo) - Number(context.state.RemExpressRevDispo))}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">Cotisations productives de retraite</td>
                    <td className="origine">
                      {applyFormat(cotProductiveRetraiteObligatoireAvant + cotProductiveRetraiteFacultativeAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td className="space"></td>
                    <td className="nouvelle pr4">
                      {applyFormat(cotProductiveRetraiteObligatoireApres + cotProductiveRetraiteFacultativeApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    <td className="extra"></td>
                    <td className="ecart">
                      {" "}
                      {ApplySignedFormat(
                        cotProductiveRetraiteObligatoireApres + cotProductiveRetraiteFacultativeApres - (cotProductiveRetraiteObligatoireAvant + cotProductiveRetraiteFacultativeAvant)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>

          <Column fill style={{ maxWidth: "520px" }}>
            <Row>
              <Column fill>
                <CardBox bpad className=" blockRoundedForce">
                  <Column center fill style={{ padding: 0 }}>
                    <Row>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            context.handleOpenRemiseDonneeRemExpressOptirem(ResultatTNS);
                          }}
                        >
                          <FaUndo />
                        </Button>
                      </Column>
                      {context.state.Partner !== "KWIPER" && (
                        <Column>
                          <Button
                            className="optiIcon"
                            onClick={() => {
                              setModalSave(true);
                            }}
                          >
                            <FaSave title="Sauvegarder" />
                          </Button>
                        </Column>
                      )}
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            GestionnaireScenarioPptx(context.state, ResultatTNS.state).then((res) => {});
                          }}
                        >
                          <FaPrint title="Imprimer" />
                        </Button>
                      </Column>
                    </Row>
                    <Modal
                      width="450px"
                      height="25%"
                      title="Sauvegarde de la simulation"
                      icon={<FaSave />}
                      visible={modalSave}
                      onClose={() => {
                        setModalSave(false);
                      }}
                    >
                      <Column fill>
                        <Row fill center>
                          <Column fill center>
                            <TextField
                              labelStyle={{ color: "var(--app-fg-color)" }}
                              label="Libellé de la simulation"
                              value={context.state.LibelleOptimisation}
                              onChange={context.handleInputChange("LibelleOptimisation")}
                              style={{ width: "370px" }}
                            />
                          </Column>
                        </Row>
                        <Row className="pager-buttons">
                          <Column fill>
                            <Button
                              onClick={() => {
                                // context.handleNewSimulation("Audit prévoyance");
                                context.handleSaveAsOptimisation(context.state.IdOptimisation, "OptiRem");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer</span>
                            </Button>
                          </Column>
                          <Column fill>
                            <Button
                              onClick={() => {
                                context.handleSaveAsOptimisation("", "OptiRem");
                                // context.handleNewSimulation("Audit prévoyance");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer sous</span>
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    </Modal>
                  </Column>
                </CardBox>
              </Column>
            </Row>
            <Row className=" blockRoundedForce">
              <Column fill>
                <Row>
                  <Column fill>
                    <Button
                      onClick={() => {
                        setModalDetail(true);
                      }}
                    >
                      <FaCalculator />
                      <span>Détails {Prenom}</span>
                    </Button>
                  </Column>
                </Row>
                {(Periode?.Statut !== "TNS individuel" || Periode?.ModeExercice === "IS") && (
                  <Row>
                    <Column fill>
                      <Button
                        onClick={() => {
                          setModalOptimum(true);
                        }}
                      >
                        <span>Optimum</span>
                      </Button>
                    </Column>
                  </Row>
                )}
                {(Periode?.Statut === "TNS Article 62" || Periode?.Statut === "Assimilé salarié" || (Periode?.Statut === "TNS individuel" && Periode?.ModeExercice === "IR")) && (
                  <Row>
                    <Column fill>
                      <Button
                        onClick={() => {
                          setModalChgtStatut(true);
                        }}
                      >
                        <span>Changement de statut</span>
                      </Button>
                    </Column>
                  </Row>
                )}
              </Column>
            </Row>

            <CardBox tpad bpad className=" blockRoundedForce dark-title">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td>Cotisations productives de retraite</td>
                    <td>Avant</td>
                    <td>Après</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad className="blockRoundedForce">
              <table className="cotProductive">
                <tbody>
                  <tr>
                    <td>Cotisations de retraite obligatoire</td>
                    <td>{applyFormat(cotProductiveRetraiteObligatoireAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(cotProductiveRetraiteObligatoireApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Cotisations de retraite facultative</td>

                    <td>{applyFormat(cotProductiveRetraiteFacultativeAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(cotProductiveRetraiteFacultativeApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>
        </Row>
      </Column>

      {/* MODAL OPTIMUM */}
      <Modal
        width="40%"
        height="50%"
        title="Optimum"
        icon={<FaGlobe />}
        visible={modalOptimum}
        onClose={() => {
          setModalOptimum(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row bpad>
                        <DropDown
                          label="Optimum"
                          value={context.state.OptiRemChoixOptimum}
                          onChange={context.handleInputChange("OptiRemChoixOptimum")}
                          options={[
                            { value: "automatique", label: "Maximiser le revenu disponible" },
                            { value: "manu", label: "Optimum avec rémunération minimale" },
                          ]}
                          large
                        />
                      </Row>
                      {context.state.OptiRemChoixOptimum === "manu" && (
                        <Row tpad>
                          <TextField
                            label="REMUNÉRATION NETTE MINIMALE"
                            value={context.state.OptiRemOptimumRemMini}
                            onChange={context.handleInputChange("OptiRemOptimumRemMini")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            large
                          />
                        </Row>
                      )}
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setModalOptimum(false);
                let elem = document.querySelector("#simulation");
                let rect = elem.getBoundingClientRect();
                setCircularWidth(rect.width + "px");
                context.handleValueChange("OptiRemLoading", true);
                context.handleCalculOptiRem("optimum", "", "", ResultatTNS, false, false);
              },
            },
          }}
        />
      </Modal>
      {/* MODAL CHANGEMENT STATUT */}
      <Modal
        width="50%"
        height="70%"
        title="Changement de statut"
        icon={<FaGlobe />}
        visible={modalChgtStatut}
        onClose={() => {
          setModalChgtStatut(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row bpad>
                        <blockquote>Par défaut, le logiciel raisonne à budget équivalent. Ce parti pris peut être modifié dans les champs de la nouvelle situation.</blockquote>
                      </Row>
                      {context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && (
                        <Row bpad>
                          <Column bpad>
                            <DropDown
                              label="Etude réalisé pour"
                              value={context.state.OptiRemCDSEtude}
                              options={[
                                {
                                  value: "client",
                                  label: context.state.Prenom,
                                },
                                {
                                  value: "conjoint",
                                  label: context.state.PrenomConjoint,
                                },
                                {
                                  value: "both",
                                  label: "Les deux",
                                },
                              ]}
                              onChange={context.handleInputChange("OptiRemCDSEtude")}
                              normal
                            />
                          </Column>
                        </Row>
                      )}
                      {(context.state.PeriodeActuelle[0]?.StatutConjoint === "Aucun" ||
                        (context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && context.state.OptiRemCDSEtude !== "conjoint")) && (
                        <>
                          <Row>
                            <h1>Changement de statut de {context.state.Prenom}</h1>
                          </Row>
                          <Row>
                            <Column>
                              <DropDown
                                label="Statut social"
                                value={context.state.OptiRemCDSStatut}
                                options={
                                  context.state.PeriodeActuelle[0]?.Statut === "TNS Article 62"
                                    ? [
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : context.state.PeriodeActuelle[0]?.Statut === "TNS individuel"
                                    ? [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                        {
                                          value: "TNS individuel",
                                          label: "TNS individuel",
                                        },
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                      ]
                                }
                                onChange={context.handleInputChange("OptiRemCDSStatut")}
                                normal
                              />
                            </Column>
                          </Row>
                          {(context.state["OptiRemCDSStatut"] === "TNS Article 62" || context.state["OptiRemCDSStatut"] === "TNS individuel") && (
                            <Row tpad>
                              <Column>
                                <DropDown
                                  label="Caisse d'affiliation"
                                  value={context.state.OptiRemCDSCaisse}
                                  options={caisse}
                                  onChange={context.handleInputChange("OptiRemCDSCaisse")}
                                  normal
                                />
                              </Column>
                              {context.state["OptiRemCDSStatut"] === "TNS individuel" && (
                                <Column>
                                  <DropDown
                                    label="MODE D'EXERCICE"
                                    value={"IS"}
                                    options={[
                                      {
                                        value: "IS",
                                        label: "En société à l'IS",
                                      },
                                    ]}
                                    // onChange={context.handleInputChange("OptiRemCDSCaisse")}
                                    disabled
                                    normal
                                  />
                                </Column>
                              )}
                            </Row>
                          )}
                          {context.state["OptiRemCDSStatut"] === "TNS individuel" && (
                            <Row tpad>
                              <Column>
                                <TextField
                                  label="% rémunération du mandat"
                                  value={context.state.OptiRemPartRemunerationMandat}
                                  onChange={context.handleInputChange("OptiRemPartRemunerationMandat")}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: "%" }}
                                  normal
                                />
                              </Column>
                            </Row>
                          )}

                          {(context.state["OptiRemCDSStatut"] === "TNS Article 62" || context.state["OptiRemCDSStatut"] === "TNS individuel") && (
                            <>
                              {context.state.OptiRemCDSCaisse === "Carcdsf" && <ParamCarcdsf Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Carcdsf2" && <ParamCarcdsf Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Carmf" && <ParamCarmf Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Carpimko" && <ParamCarpimko Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Carpv" && <ParamCarpv Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Cavec" && <ParamCavec Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Cavp" && <ParamCavp Outil="OptiRem" ClientConjoint="" />}
                              {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="" />} */}
                              {context.state.OptiRemCDSCaisse === "Cnbf" && (
                                <>
                                  <ParamCnbf Outil="OptiRem" ClientConjoint="" />
                                  <Row tpad>
                                    <Column>
                                      <ParamLpaPageModal Outil="OptiRem" ClientConjoint="" />
                                    </Column>
                                  </Row>
                                </>
                              )}
                              {context.state.OptiRemCDSCaisse === "Crn" && <ParamCrn Outil="OptiRem" ClientConjoint="" />}
                              {context.state.OptiRemCDSCaisse === "Msa" && <ParamMsa Outil="OptiRem" ClientConjoint="" />}
                            </>
                          )}

                          {/* <Row tpad bpad>
                            <Column>
                              <DropDown
                                label="Raisonnement"
                                value={context.state.OptiRemCDSRaisonnement}
                                options={[
                                  {
                                    value: "cout_remuneration",
                                    label: "A budget équivalent",
                                  },
                                  {
                                    value: "remuneration_nette",
                                    label: "A rémunération nette équivalente",
                                  },
                                ]}
                                onChange={context.handleInputChange("OptiRemCDSRaisonnement")}
                                normal
                              />
                            </Column>
                          </Row> */}
                          {context.state.OptiRemCDSStatut === "Assimilé salarié" && (
                            <Row tpad bpad>
                              <ParamFicheDePaiePageModal Outil="OptiRem" ClientConjoint="" />
                            </Row>
                          )}
                        </>
                      )}
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setModalChgtStatut(false);
                context.handleChangementStatut("", ResultatTNS);
              },
            },
          }}
        />
      </Modal>

      {/* MODAL DETAIL CLIENT */}
      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetail}
        onClose={() => {
          setModalDetail(false);
        }}
      >
        {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
          <DetailTNSPageModal
            AccessRemExpress={true}
            Outil=""
            ClientConjoint=""
            Prenom={Prenom}
            Caisse={Periode?.Caisse}
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerp}
            GraphDisponible={ResultatTNS.state["OptiRemRemuneration"].GraphDisponible}
            DetailCotisations={ResultatTNS.state["OptiRemRemuneration"].DetailCotisations}
            DetailAssiette={ResultatTNS.state["OptiRemRemuneration"].DetailAssiette}
            DetailCSG={ResultatTNS.state["OptiRemRemuneration"].DetailCSG}
            TotalChargesSociales={ResultatTNS.state["OptiRemRemuneration"].TotalChargesSociales}
            DetailAssietteClient={ResultatTNS.state["OptiRemRemuneration"].DetailAssiette}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            AccessRemExpress={true}
            Outil=""
            Prenom={Prenom}
            ClientConjoint=""
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerp}
            DetailFicheDePaie={ResultatTNS.state["OptiRemRemuneration"].DetailFicheDePaie}
            DetailAssietteClient={ResultatTNS.state["OptiRemRemuneration"].DetailFicheDePaie}
            GraphDisponible={ResultatTNS.state["OptiRemRemuneration"].GraphDisponible}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default RemExpressOptiRemPage;
