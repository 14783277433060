import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { caisse } from "components/app/RemExpertIntialState";
import { caisseSELARL } from "components/app/RemExpertIntialState";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import ParamFicheDePaiePageModal from "pages/audit complet/situation professionnelle/modules/parametre fiche de paie modal";
import ParamCarcdsf from "./ParamCaisse/ParamCarcdsf";
import ParamCarmf from "./ParamCaisse/ParamCarmf";
import ParamCarpimko from "./ParamCaisse/ParamCarpimko";
import ParamCarpv from "./ParamCaisse/ParamCarpv";
import ParamCavec from "./ParamCaisse/ParamCavec";
import ParamCavp from "./ParamCaisse/ParamCavp";
import ParamCipav from "./ParamCaisse/ParamCipav";
import ParamCnbf from "./ParamCaisse/ParamCnbf";
import ParamCrn from "./ParamCaisse/ParamCrn";
import ParamMsa from "./ParamCaisse/ParamMsa";
import ParamLpaPageModal from "pages/audit complet/situation professionnelle/modules/parametre lpa modal";

const typeRemuneration = [
  {
    value: "remuneration_nette",
    label: "Net",
  },
  {
    value: "remuneration_brute",
    label: "Brut",
  },
  {
    value: "brut_imposable",
    label: "Imposable",
  },
];
const typeRemunerationBicBnc = [
  {
    value: "brut_imposable",
    label: "Imposable",
  },
];
const typeRemunerationSalarie = [
  {
    value: "salaire_brut",
    label: "Brut",
  },
  {
    value: "salaire_net",
    label: "Net",
  },

  {
    value: "brut_imposable",
    label: "Imposable",
  },
];

function Remuneration(props) {
  const context = useContext(AppContext);
  const typeRem = {
    "TNS Article 62": typeRemuneration,
    "Assimilé salarié": typeRemunerationSalarie,
    "Salarié cadre": typeRemunerationSalarie,
    "Salarié non cadre": typeRemunerationSalarie,
    "TNS individuel": typeRemunerationBicBnc,
  };

  const statutSociaux = () => {
    let statutSocial = [];
    const statutSocialTns = [
      {
        value: "TNS Article 62",
        label: "TNS article 62",
      },
    ];
    const statutSocialTnsInd = [
      {
        value: "TNS individuel",
        label: "TNS Individuel",
      },
    ];
    const statutSocialAssimileSalarie = [
      {
        value: "Assimilé salarié",
        label: "Assimilé salarié",
      },
    ];
    const statutSocialSalarieCadre = [
      {
        value: "Salarié cadre",
        label: "Salarié cadre",
      },
    ];
    const statutSocialSalarieNonCadre = [
      {
        value: "Salarié non cadre",
        label: "Salarié non cadre",
      },
    ];

    switch (context.state.SituationPro) {
      case "SalarieCadre":
      case "ECSalarie":
      case "AvocatSalarie":
        statutSocial = [...statutSocialSalarieCadre];
        break;
      case "SalarieNonCadre":
        statutSocial = [...statutSocialSalarieNonCadre];
        break;
      default:
        if (context.state.Fiscalite === "IR") {
          statutSocial = [...statutSocialTnsInd];
        } else {
          switch (context.state.FormeJuridique) {
            case "EIRL":
            case "EURL":
              statutSocial = [...statutSocialTns];
              break;
            case "SELARL":
            case "SELARLU":
            case "SELAS":
            case "SELASU":
              statutSocial = [...statutSocialTnsInd, ...statutSocialTns];
              break;
            case "SAS":
            case "SA":
            case "SASU":
              statutSocial = [...statutSocialAssimileSalarie];
              break;
            default:
              statutSocial = [...statutSocialTns, ...statutSocialAssimileSalarie];
          }
        }
    }

    return statutSocial;
  };

  const statutSociauxConj = () => {
    let statutSocial = [
      {
        value: "Aucun",
        label: "Aucun",
      },
    ];
    const statutSocialTns = [
      {
        value: "TNS Article 62",
        label: "TNS article 62",
      },
    ];
    const statutSocialTnsInd = [
      {
        value: "TNS individuel",
        label: "TNS Individuel",
      },
    ];
    const statutSocialAssimileSalarie = [
      {
        value: "Assimilé salarié",
        label: "Assimilé salarié",
      },
    ];
    const statutSocialSalarieCadre = [
      {
        value: "Salarié cadre",
        label: "Salarié cadre",
      },
    ];
    const statutSocialSalarieNonCadre = [
      {
        value: "Salarié non cadre",
        label: "Salarié non cadre",
      },
    ];

    if (context.state.SituationPro === "SalarieCadre" || context.state.SituationPro === "SalarieNonadre") {
      statutSocial = [...statutSocial, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
      return statutSocial;
    }

    switch (context.state.Statut) {
      case "TNS individuel":
        if (context.state.FormeJuridique === "EARL") {
          statutSocial = [...statutSocial, ...statutSocialTnsInd, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
        } else if (context.state.FormeJuridique === "EARL" || context.state.FormeJuridique === "SELARL" || context.state.FormeJuridique === "SELAS") {
          statutSocial = [...statutSocial, ...statutSocialTns, ...statutSocialTnsInd, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
        } else {
          statutSocial = [...statutSocial, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
        }
        break;
      default:
        switch (context.state.FormeJuridique) {
          case "EIRL":
          case "EURL":
          case "SASU":
          case "SELARLU":
          case "SELASU":
            statutSocial = [...statutSocial, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
            break;
          case "SAS":
          case "SA":
            statutSocial = [...statutSocial, ...statutSocialAssimileSalarie, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
            break;
          case "SELARL":
          case "SELAS":
            statutSocial = [...statutSocial, ...statutSocialTns, ...statutSocialTnsInd, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
            break;
          case "EARL":
          case "SCEA":
            if (context.state.Statut === "TNS Article 62") {
              statutSocial = [...statutSocial, ...statutSocialTns, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
            } else {
              statutSocial = [...statutSocial, ...statutSocialAssimileSalarie, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
            }
            break;
          default:
            statutSocial = [...statutSocial, ...statutSocialTns, ...statutSocialAssimileSalarie, ...statutSocialSalarieCadre, ...statutSocialSalarieNonCadre];
        }
    }

    return statutSocial;
  };
  const a = statutSociauxConj();
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <h1>Rémunération de {context.state["Prenom" + props.ClientConjoint]}</h1>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Statut social"
                  value={context.state.Statut}
                  options={statutSociaux()}
                  onChange={(e) => {
                    context.handleChoixStatut("Statut", props.ClientConjoint, e);
                  }}
                  normal
                />
              </Column>
              <Column lpad>
                {context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel" ? (
                  context.state.FormeJuridique === "SELARL" ||
                  context.state.FormeJuridique === "SELARLU" ||
                  context.state.FormeJuridique === "SELAS" ||
                  context.state.FormeJuridique === "SELASU" ? (
                    <DropDown label="Caisse d'affiliation" value={context.state.Caisse} options={caisseSELARL} onChange={context.handleChoixCaisse} normal />
                  ) : (
                    <DropDown label="Caisse d'affiliation" value={context.state.Caisse} options={caisse} onChange={context.handleChoixCaisse} normal />
                  )
                ) : (
                  <DropDown
                    label="Caisse d'affiliation"
                    value={"Regime"}
                    options={[
                      {
                        value: "Regime",
                        label: "Régime général",
                      },
                    ]}
                    normal
                  />
                )}
              </Column>
            </Row>
            {(context.state.Statut === "TNS Article 62" || context.state.Statut === "TNS individuel") && (
              <>
                {context.state.Caisse === "Carcdsf" && <ParamCarcdsf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carcdsf2" && <ParamCarcdsf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carmf" && <ParamCarmf Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carpimko" && <ParamCarpimko Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Carpv" && <ParamCarpv Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Cavec" && <ParamCavec Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Cavp" && <ParamCavp Outil="" ClientConjoint="" />}
                {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="" />} */}
                {context.state.Caisse === "Cnbf" && (
                  <>
                    <ParamCnbf Outil="" ClientConjoint="" />
                    <Row>
                      <Column>
                        <ParamLpaPageModal Outil="" ClientConjoint="" />
                      </Column>
                    </Row>
                  </>
                )}
                {context.state.Caisse === "Crn" && <ParamCrn Outil="" ClientConjoint="" />}
                {context.state.Caisse === "Msa" && <ParamMsa Outil="" ClientConjoint="" />}
              </>
            )}
            <Row>
              <Column>
                <TextField label="Montant annuel" value={context.state.Remuneration} onChange={context.handleRemuneration} dataType="uint" format={{ thousands: " ", unit: " €" }} normal />
              </Column>
              <Column hpad>
                <DropDown
                  label="Type de rémunération"
                  value={context.state.TypeRemuneration}
                  options={typeRem[context.state.Statut]}
                  onChange={context.handleInputChange("TypeRemuneration")}
                  normal
                />
              </Column>
              <Column>
                <TextField
                  label="Avantages en nature divers"
                  value={context.state.AvantageNature}
                  onChange={context.handleInputChange("AvantageNature")}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                />
              </Column>
            </Row>
            {context.state.Statut === "TNS individuel" &&
              context.state.Caisse !== "RsiCommercant" &&
              context.state.Caisse !== "RsiArtisan" &&
              context.state.Caisse !== "Msa" &&
              context.state.FormeJuridique !== "SELAS" &&
              context.state.FormeJuridique !== "SELASU" &&
              context.state.ModeExercice === "IS" && (
                <Row>
                  <Column>
                    <TextField
                      label="% rémunération du mandat"
                      value={context.state.PartRemunerationMandat}
                      onChange={context.handleInputChange("PartRemunerationMandat")}
                      dataType="uint"
                      format={{ thousands: " ", unit: "%" }}
                      normal
                    />
                  </Column>
                </Row>
              )}
            {(context.state.Statut === "Assimilé salarié" || context.state.Statut === "Salarié cadre" || context.state.Statut === "Salarié non cadre") && (
              <Row>
                <Column>
                  <ParamFicheDePaiePageModal Outil="" ClientConjoint="" />
                </Column>
              </Row>
            )}
            {context.state.InclureConjoint && props.ClientConjoint === "" && context.state.PeriodeActuelleConjoint.length === 0 && (
              <>
                <Row>
                  <h1>Rémunération de {context.state.PrenomConjoint}</h1>
                </Row>
                <Row>
                  <Column>
                    <DropDown
                      label="Statut social"
                      value={context.state.StatutConjoint}
                      options={statutSociauxConj()}
                      onChange={(e) => {
                        context.handleChoixStatutConjoint("StatutConjoint", e);
                      }}
                      normal
                    />
                  </Column>
                  <Column lpad>
                    {(context.state.StatutConjoint === "TNS Article 62" || context.state.StatutConjoint === "TNS individuel") &&
                      context.state.Statut !== "TNS Article 62" &&
                      context.state.Statut !== "TNS individuel" && (
                        <DropDown label="Caisse d'affiliation" value={context.state.Caisse} options={caisse} onChange={context.handleChoixCaisse} normal />
                      )}
                  </Column>
                </Row>
                {context.state.StatutConjoint !== "Aucun" && (
                  <>
                    <Row>
                      <Column>
                        <TextField label="Début de période" value={context.state.DebutPeriodeCarriereActuelle} dataType="date" disabled normal />
                      </Column>
                      <Column lpad>
                        <DropDown
                          label="TERME RETENU"
                          value={context.state.FinCarriereActuelleConjoint}
                          options={[
                            {
                              value: "DepartRetraite",
                              label: "Départ à la retraite",
                            },
                            {
                              value: "Personnalise",
                              label: "Personnalisé",
                            },
                            {
                              value: "Client",
                              label: "Identique au client principal",
                            },
                          ]}
                          onChange={context.handleFinCarriereActuelleConjoint}
                          normal
                        />
                      </Column>
                      <Column lpad>
                        <TextField
                          label="Fin de période"
                          value={context.state.FinPeriodeCarriereActuelleConjoint}
                          dataType="date"
                          disabled={context.state.FinCarriereActuelleConjoint === "Personnalise" ? false : true}
                          onChange={context.handleInputChange("FinPeriodeCarriereActuelleConjoint")}
                          normal
                        />
                      </Column>
                    </Row>
                    {(context.state.StatutConjoint === "TNS Article 62" || context.state.StatutConjoint === "TNS individuel") && (
                      <>
                        {context.state.Caisse === "Carcdsf" && <ParamCarcdsf Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Carcdsf2" && <ParamCarcdsf Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Carmf" && <ParamCarmf Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Carpimko" && <ParamCarpimko Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Carpv" && <ParamCarpv Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Cavec" && <ParamCavec Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Cavp" && <ParamCavp Outil="" ClientConjoint="Conjoint" />}
                        {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="Conjoint" />} */}
                        {context.state.Caisse === "Cnbf" && (
                          <>
                            <ParamCnbf Outil="" ClientConjoint="Conjoint" />
                            <Row>
                              <Column>
                                <ParamLpaPageModal Outil="" ClientConjoint="Conjoint" />
                              </Column>
                            </Row>
                          </>
                        )}
                        {context.state.Caisse === "Crn" && <ParamCrn Outil="" ClientConjoint="Conjoint" />}
                        {context.state.Caisse === "Msa" && <ParamMsa Outil="" ClientConjoint="Conjoint" />}
                      </>
                    )}
                    <Row>
                      <Column>
                        <TextField
                          label="Montant annuel"
                          value={context.state.RemunerationConjoint}
                          onChange={context.handleInputChange("RemunerationConjoint")}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                        />
                      </Column>
                      <Column hpad>
                        <DropDown
                          label="Type de rémunération"
                          value={context.state.TypeRemunerationConjoint}
                          options={typeRem[context.state.StatutConjoint]}
                          onChange={context.handleInputChange("TypeRemunerationConjoint")}
                          normal
                        />
                      </Column>
                      <Column>
                        <TextField
                          label="Avantages en nature divers"
                          value={context.state.AvantageNatureConjoint}
                          onChange={context.handleInputChange("AvantageNatureConjoint")}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                        />
                      </Column>
                    </Row>
                    {context.state.StatutConjoint === "TNS individuel" &&
                      context.state.Caisse !== "RsiCommercant" &&
                      context.state.Caisse !== "RsiArtisan" &&
                      context.state.Caisse !== "Msa" &&
                      context.state.FormeJuridique !== "SELAS" &&
                      context.state.FormeJuridique !== "SELASU" &&
                      context.state.ModeExercice === "IS" && (
                        <Row>
                          <Column>
                            <TextField
                              label="% rémunération du mandat"
                              value={context.state.PartRemunerationMandatConjoint}
                              onChange={context.handleInputChange("PartRemunerationMandatConjoint")}
                              dataType="uint"
                              format={{ thousands: " ", unit: "%" }}
                              normal
                            />
                          </Column>
                        </Row>
                      )}
                    {(context.state.StatutConjoint === "Assimilé salarié" || context.state.StatutConjoint === "Salarié cadre" || context.state.StatutConjoint === "Salarié non cadre") && (
                      <Row>
                        <Column>
                          <ParamFicheDePaiePageModal Outil="" ClientConjoint="Conjoint" />
                        </Column>
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}

export default Remuneration;
