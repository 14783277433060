import "./simulation.css";
import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaGlobe, FaIdBadge, FaPrint, FaSave, FaUndo } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import TextField from "components/ui/controls/text-field/text-field";
import OptimisationRemunerationTns from "components/app/AuditComplet/Optimisation/OptimisationRemunerationTns";
import OptimisationRemunerationSalarie from "components/app/AuditComplet/Optimisation/OptimisationRemunerationSalarie";
import CheckBox from "components/ui/controls/check-box/check-box";
import OptimisationResAvtIS from "components/app/AuditComplet/Optimisation/OptimisationResAvtIS";
import OptimisationRevenus from "components/app/AuditComplet/Optimisation/OptimisationRevenus";
import { ApplySignedFormat } from "components/app/ApplySignedFormat";
import Pager from "components/ui/controls/pager/pager";
import DropDown from "components/ui/controls/drop-down/drop-down";
import DetailTNSPageModal from "../résultat/detail/rémuneration/detailTNSPager";
import DetailSalariePageModal from "../résultat/detail/rémuneration/detailSalariePager";
import { caisse } from "components/app/RemExpertIntialState";
import ParamCarcdsf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarcdsf";
import ParamCarmf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarmf";
import ParamCarpimko from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpimko";
import ParamCarpv from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCarpv";
import ParamCavec from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavec";
import ParamCavp from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCavp";
import ParamCnbf from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCnbf";
import ParamLpaPageModal from "../situation professionnelle/modules/parametre lpa modal";
import ParamCrn from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamCrn";
import ParamMsa from "components/app/AuditComplet/SituationPro/ParamCaisse/ParamMsa";
import ParamFicheDePaiePageModal from "../situation professionnelle/modules/parametre fiche de paie modal";
import { AuditOptiRemWord } from "components/app/Api/Rapport/ApiRapport";
// import DetailTNSPageModal from "./detail/rémuneration/detailTNSPager";
// import DetailSalariePageModal from "./detail/rémuneration/detailSalariePager";
// import DropDown from "components/ui/controls/drop-down/drop-down";
// import { AuditCompletWord } from "components/app/Api/Rapport/ApiRapport";

const OptiRemPage = (props) => {
  let { ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [modalOptimum, setModalOptimum] = useState(false);
  const [modalChgtStatut, setModalChgtStatut] = useState(false);
  const [modalDetailConjoint, setModalDetailConjoint] = useState(false);
  const PeriodeClient = context.state["PeriodeActuelle"][0];
  const PeriodeConjointActuel = context.state["PeriodeActuelleConjoint"] === undefined ? undefined : context.state["PeriodeActuelleConjoint"][0];
  const Periode = context.state["OptiRemPeriodeActuelle" + ClientConjoint] === undefined ? undefined : context.state["OptiRemPeriodeActuelle" + ClientConjoint][0];
  const PeriodeConjoint = context.state["OptiRemPeriodeActuelleConjoint"] === undefined ? undefined : context.state["OptiRemPeriodeActuelleConjoint"][0];
  const Prenom = context.state["Prenom" + ClientConjoint];
  const [AffichConjointAssocie, setAffichConjointAssocie] = useState(false);
  const [circularWidth, setCircularWidth] = useState("0px");

  useEffect(() => {
    context.handleCalculOptiRem("cout_entreprise", "OptiRemCoutEntreprise" + ClientConjoint, ClientConjoint, ResultatTNS, false, context.state["OptiRemCoutEntreprise" + ClientConjoint]);
  }, [props.ClientConjoint]);

  let TotalRenteAnnuelleAvant = 0;
  let TotalRenteObligatoireAvant = 0;
  let TotalRenteFacultativeAvant = 0;
  let TotalCapitalFractionneAvant = 0;
  let IJMaxAvant = 0;
  let InvaliditeTotaleAvant = 0;
  let CapitalDecesAvant = 0;

  let TotalRenteAnnuelleApres = 0;
  let TotalRenteObligatoireApres = 0;
  let TotalRenteFacultativeApres = 0;
  let TotalCapitalFractionneApres = 0;

  let IJMaxApres = 0;
  let InvaliditeTotaleApres = 0;
  let CapitalDecesApres = 0;

  if (AffichConjointAssocie === false) {
    let ResultatRetraiteAvant =
      context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS Article 62" || context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS individuel"
        ? ResultatTNS.state["Retraite" + ClientConjoint]
        : ResultatSalarie.state["Retraite" + ClientConjoint];
    TotalRenteAnnuelleAvant = ResultatRetraiteAvant.TotalRetraiteNetteChoixDepartRetraite + ResultatRetraiteAvant.TotalCapitalFractionneChoixDepartRetraite;
    TotalRenteObligatoireAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalPensionNette;
    TotalRenteFacultativeAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalRenteNette;
    TotalCapitalFractionneAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalCapitalFractionne;

    let ResultatRetraiteApres = context.state["OptiRemResultatRetraite" + ClientConjoint];
    TotalRenteAnnuelleApres = ResultatRetraiteApres?.TotalRetraiteNetteChoixDepartRetraite + ResultatRetraiteApres?.TotalCapitalFractionneChoixDepartRetraite;
    TotalRenteObligatoireApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalPensionNette;
    TotalRenteFacultativeApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalRenteNette;
    TotalCapitalFractionneApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalCapitalFractionne;

    let ResultatPrevoyanceAvant =
      context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS Article 62" || context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS individuel"
        ? ResultatTNS.state["PrevTNS" + ClientConjoint]
        : ResultatSalarie.state["PrevSalarie" + ClientConjoint];

    IJMaxAvant = ResultatPrevoyanceAvant.IJMax;
    InvaliditeTotaleAvant = ResultatPrevoyanceAvant.InvaliditeTotale;
    CapitalDecesAvant = ResultatPrevoyanceAvant.CapitalDeces;

    let ResultatPrevoyanceApres = context.state["OptiRemResultatPrevoyance" + ClientConjoint];
    IJMaxApres = ResultatPrevoyanceApres?.AffichageResultat?.IJMax;
    InvaliditeTotaleApres = ResultatPrevoyanceApres?.AffichageResultat?.InvaliditeTotale;
    CapitalDecesApres = ResultatPrevoyanceApres?.AffichageResultat?.CapitalDeces;
  } else {
    let ResultatRetraiteAvant =
      context.state["PeriodeActuelle"][0]?.StatutConjoint === "TNS Article 62" || context.state["PeriodeActuelle"][0]?.StatutConjoint === "TNS individuel"
        ? ResultatTNS.state.RetraiteConjoint
        : ResultatSalarie.state.RetraiteConjoint;
    TotalRenteAnnuelleAvant = ResultatRetraiteAvant.TotalRetraiteNetteChoixDepartRetraite + ResultatRetraiteAvant.TotalCapitalFractionneChoixDepartRetraite;
    TotalRenteObligatoireAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalPensionNette;
    TotalRenteFacultativeAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalRenteNette;
    TotalCapitalFractionneAvant = ResultatRetraiteAvant.InfosRetraiteChoixDepart.TotalCapitalFractionne;

    let ResultatRetraiteApres = context.state.OptiRemResultatRetraiteConjoint;
    TotalRenteAnnuelleApres = ResultatRetraiteApres?.TotalRetraiteNetteChoixDepartRetraite + ResultatRetraiteApres?.TotalCapitalFractionneChoixDepartRetraite;
    TotalRenteObligatoireApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalPensionNette;
    TotalRenteFacultativeApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalRenteNette;
    TotalCapitalFractionneApres = ResultatRetraiteApres?.RetraiteChoixDepartRetraite?.TotalCapitalFractionne;

    let ResultatPrevoyanceAvant =
      context.state["PeriodeActuelle" + ClientConjoint][0]?.StatutConjoint === "TNS Article 62" || context.state["PeriodeActuelle" + ClientConjoint][0]?.StatutConjoint === "TNS individuel"
        ? ResultatTNS.state.PrevTNSConjoint
        : ResultatSalarie.state.PrevSalarieConjoint;

    IJMaxAvant = ResultatPrevoyanceAvant.IJMax;
    InvaliditeTotaleAvant = ResultatPrevoyanceAvant.InvaliditeTotale;
    CapitalDecesAvant = ResultatPrevoyanceAvant.CapitalDeces;

    let ResultatPrevoyanceApres = context.state.OptiRemResultatPrevoyanceConjoint;
    IJMaxApres = ResultatPrevoyanceApres?.AffichageResultat?.IJMax;
    InvaliditeTotaleApres = ResultatPrevoyanceApres?.AffichageResultat?.InvaliditeTotale;
    CapitalDecesApres = ResultatPrevoyanceApres?.AffichageResultat?.CapitalDeces;
  }

  const handleGetEnvEntreprise = () => {
    let EnvEntr = 0;
    if (context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS Article 62" || context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS individuel") {
      EnvEntr += ResultatTNS.state["RemunerationTNS" + ClientConjoint].CoutRemuneration + ResultatTNS.state["RemunerationTNS" + ClientConjoint].CotisationsFacultativesFraisPro;
    } else {
      EnvEntr += ResultatSalarie.state["RemunerationSalarie" + ClientConjoint].CoutRemuneration;
    }
    if (context.state.InclureConjoint === true && context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        EnvEntr += ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration + ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesFraisPro;
      } else {
        EnvEntr += ResultatSalarie.state.RemunerationSalarieConjoint.CoutRemuneration;
      }
    }
    EnvEntr += Periode?.ResAvtIs;
    return EnvEntr;
  };
  const handleRevenusPercus = () => {
    let RevPercus = 0;
    if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
      RevPercus += Number(ResultatTNS.state["RemunerationTNS"].RemunerationNette);
    } else {
      RevPercus += Number(ResultatSalarie.state["RemunerationSalarie"].SalaireNet);
    }
    if (PeriodeConjointActuel !== undefined) {
      if (PeriodeConjointActuel?.Statut === "TNS Article 62" || PeriodeConjointActuel?.Statut === "TNS individuel") {
        RevPercus += Number(ResultatTNS.state["RemunerationTNSConjoint"].RemunerationNette);
      } else {
        RevPercus += Number(ResultatSalarie.state["RemunerationSalarieConjoint"].SalaireNet);
      }
    }
    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        RevPercus += Number(ResultatTNS.state.RemunerationTNSConjoint.RemunerationNette);
      } else {
        RevPercus += Number(ResultatSalarie.state.RemunerationSalarieConjoint.SalaireNet);
      }
    }
    const DividendeClient = PeriodeClient && PeriodeClient.DividendeBrut ? PeriodeClient.DividendeBrut : 0;
    const DividendeConjoint = PeriodeConjointActuel && PeriodeConjointActuel.DividendeBrut ? PeriodeConjointActuel.DividendeBrut : 0;
    RevPercus += Number(DividendeClient) + Number(DividendeConjoint);
    return RevPercus;
  };

  const handleCotisationsFacPerso = () => {
    let CotFacPerso = 0;
    if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
      CotFacPerso += Number(ResultatTNS.state["RemunerationTNS"].VersementFacultatifPerso);
    } else {
      CotFacPerso += Number(ResultatSalarie.state["RemunerationSalarie"].VersementFacultatifPerso);
    }

    // console.log(CotFacPerso);
    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        CotFacPerso += Number(ResultatTNS.state.RemunerationTNSConjoint.VersementFacultatifPerso);
      } else {
        CotFacPerso += Number(ResultatSalarie.state.RemunerationSalarieConjoint.VersementFacultatifPerso);
      }
    }

    // console.log(CotFacPerso);
    if (PeriodeConjointActuel !== undefined) {
      if (PeriodeConjointActuel?.Statut === "TNS Article 62" || PeriodeConjointActuel?.Statut === "TNS individuel") {
        CotFacPerso += Number(ResultatTNS.state["RemunerationTNSConjoint"].VersementFacultatifPerso);
      } else {
        CotFacPerso += Number(ResultatSalarie.state["RemunerationSalarieConjoint"].VersementFacultatifPerso);
      }
    }
    // console.log(CotFacPerso);
    return CotFacPerso;
  };

  const handleRevenusDispo = () => {
    return handleRevenusPercus() + ResultatTNS.state.DetailImpot?.AutresRevenusFoyer - handleCotisationsFacPerso() - ResultatTNS.state.DetailImpot?.IRTotal;
  };
  return (
    <>
      <Column fill>
        <Row>
          <Column fill style={{ minWidth: "640px", maxWidth: "820px" }}>
            <CardBox bpad className="light-title blockRoundedForce">
              <table id="simulation" className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">&nbsp;</td>
                    <td className="origine">Situation d'origine</td>
                    <td className="space">&nbsp;</td>
                    <td className="nouvelle" style={{ textAlign: "center" }}>
                      Nouvelle situation
                    </td>
                    <td className="extra">&nbsp;</td>
                    <td className="ecart">Ecart</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">Enveloppe entreprise</td>
                    <td className="origine">{applyFormat(handleGetEnvEntreprise(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="space">&nbsp;</td>
                    <td className="nouvelle">
                      {applyFormat(context.state["OptiRemCoutEntreprise" + ClientConjoint], "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      {/* <TextField
                            iconBefore={
                              <Button icon>
                                <FaCalculator />
                              </Button>
                            }
                            value={context.state["OptiRemCoutEntreprise" + ClientConjoint]}
                            // onKeyUp={(e)=>{context.handleMontantOptiRem("OptiRemCoutEntreprise",e)}}
                            onChange={(e) => {
                              context.handleCalculOptiRem("cout_entreprise", ["OptiRemCoutEntreprise" + ClientConjoint], ClientConjoint, ResultatTNS,false, e);
                            }}
                            disabled={!context.state.OptiRemUnlockCoutEntreprise}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            small
                          /> */}
                    </td>
                    <td className="extra">
                      <CheckBox
                        checked={true}
                        unchecked={false}
                        value={context.state["OptiRemUnlockCoutEntreprise" + ClientConjoint]}
                        onChange={context.handleCheckChange("OptiRemUnlockCoutEntreprise" + ClientConjoint)}
                      />
                    </td>
                    <td className="ecart">{ApplySignedFormat(Number(context.state["OptiRemCoutEntreprise" + ClientConjoint]) - handleGetEnvEntreprise())}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
              <OptimisationRemunerationTns ClientConjoint={ClientConjoint} Prenom={Prenom} />
            ) : (
              <OptimisationRemunerationSalarie ClientConjoint={ClientConjoint} Prenom={Prenom} />
            )}

            {context.state.InclureConjoint === true &&
              PeriodeClient?.StatutConjoint !== "Aucun" &&
              ClientConjoint === "" &&
              (Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel" ? (
                <OptimisationRemunerationTns ClientConjoint="ConjointAssocie" Prenom={context.state.PrenomConjoint} />
              ) : (
                <OptimisationRemunerationSalarie ClientConjoint="ConjointAssocie" Prenom={context.state.PrenomConjoint} />
              ))}

            {(Periode?.Statut !== "TNS individuel" || Periode?.ModeExercice === "IS") && <OptimisationResAvtIS ClientConjoint={ClientConjoint} Periode={Periode} Prenom={Prenom} />}

            <OptimisationRevenus
              ClientConjoint={ClientConjoint}
              Revenus={handleRevenusPercus()}
              Periode={Periode}
              AutresRevenus={ResultatTNS.state.DetailImpot?.AutresRevenusFoyer}
              IRTotal={ResultatTNS.state.DetailImpot?.IRTotal}
              CotisationsFacPerso={handleCotisationsFacPerso()}
            />
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td className="libelle">Revenu disponible</td>
                    <td className="origine">{applyFormat(handleRevenusDispo(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="space">&nbsp;</td>
                    <td className="nouvelle">
                      {PeriodeConjoint === undefined ? (
                        <TextField
                          iconBefore={
                            <Button icon>
                              <FaCalculator />
                            </Button>
                          }
                          value={context.state.OptiRemRevDispo}
                          // onKeyUp={(e)=>{context.handleMontantOptiRem("OptiRemRevDispo",e)}}
                          onChange={(e) => {
                            context.handleCalculOptiRem("revenu_dispo", "OptiRemRevDispo", ClientConjoint, ResultatTNS, false, e);
                          }}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          small
                        />
                      ) : (
                        applyFormat(context.state.OptiRemRevDispo, "uint", { thousands: " ", decimal: ",", unit: " €" })
                      )}
                    </td>
                    <td className="extra"></td>
                    <td className="ecart"> {ApplySignedFormat(Number(context.state.OptiRemRevDispo) - Number(handleRevenusDispo()))}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>

          <Column fill style={{ maxWidth: "530px" }}>
            <Row>
              <Column fill>
                <CardBox bpad className=" blockRoundedForce">
                  <Column center fill style={{ padding: 0 }}>
                    <Row>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            props.onReset();
                            context.handleOpenRemiseDonneeOptirem(ClientConjoint, ResultatTNS, ResultatSalarie);
                          }}
                        >
                          <FaUndo />
                        </Button>
                      </Column>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            setModalSave(true);
                          }}
                        >
                          <FaSave title="Sauvegarder" />
                        </Button>
                      </Column>
                      <Column>
                        <Button
                          className="optiIcon"
                          onClick={() => {
                            AuditOptiRemWord(context.state, ResultatTNS.state, ResultatSalarie.state);
                          }}
                        >
                          <FaPrint title="Imprimer" />
                        </Button>
                      </Column>
                    </Row>
                    <Modal
                      width="450px"
                      height="25%"
                      title="Sauvegarde de la simulation"
                      icon={<FaSave />}
                      visible={modalSave}
                      onClose={() => {
                        setModalSave(false);
                      }}
                    >
                      <Column fill>
                        <Row fill center>
                          <Column fill center>
                            <TextField
                              labelStyle={{ color: "var(--app-fg-color)" }}
                              label="Libellé de la simulation"
                              value={context.state.LibelleOptimisation}
                              onChange={context.handleInputChange("LibelleOptimisation")}
                              style={{ width: "370px" }}
                            />
                          </Column>
                        </Row>
                        <Row className="pager-buttons">
                          <Column fill>
                            <Button
                              onClick={() => {
                                // context.handleNewSimulation("Audit prévoyance");
                                context.handleSaveAsOptimisation(context.state.IdOptimisation, "OptiRem");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer</span>
                            </Button>
                          </Column>
                          <Column fill>
                            <Button
                              onClick={() => {
                                context.handleSaveAsOptimisation("", "OptiRem");
                                // context.handleNewSimulation("Audit prévoyance");
                                setModalSave(false);
                              }}
                            >
                              <span>Enregistrer sous</span>
                            </Button>
                          </Column>
                        </Row>
                      </Column>
                    </Modal>
                  </Column>
                </CardBox>
              </Column>
            </Row>
            <Row className=" blockRoundedForce">
              <Column fill>
                <Row>
                  <Column fill>
                    <Button
                      onClick={() => {
                        setModalDetail(true);
                      }}
                    >
                      <FaCalculator />
                      <span>Détails {Prenom}</span>
                    </Button>
                  </Column>
                </Row>
                {context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && (
                  <Row>
                    <Column fill>
                      <Button
                        onClick={() => {
                          setModalDetailConjoint(true);
                        }}
                      >
                        <FaCalculator />
                        <span>Détails {context.state.PrenomConjoint}</span>
                      </Button>
                    </Column>
                  </Row>
                )}
                {PeriodeClient?.StatutConjoint === "Aucun" && (Periode?.Statut !== "TNS individuel" || Periode?.ModeExercice === "IS") && (
                  <Row>
                    <Column fill>
                      <Button
                        onClick={() => {
                          setModalOptimum(true);
                        }}
                      >
                        <span>Optimum</span>
                      </Button>
                    </Column>
                  </Row>
                )}
                {(Periode?.Statut === "TNS Article 62" || Periode?.Statut === "Assimilé salarié"|| Periode?.Statut === "Salarié cadre" || Periode?.Statut === "Salarié non cadre"|| (Periode?.Statut === "TNS individuel" && Periode?.ModeExercice === "IR")) && (
                  <Row>
                    <Column fill>
                      <Button
                        onClick={() => {
                          setModalChgtStatut(true);
                        }}
                      >
                        <span>Changement de statut</span>
                      </Button>
                    </Column>
                  </Row>
                )}
              </Column>
            </Row>

            <CardBox tpad bpad className=" blockRoundedForce dark-title">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: "0", paddingRight: "0" }}>
                      {Periode?.StatutConjoint !== "Aucun" ? (
                        <DropDown
                          label="Prestations"
                          value={AffichConjointAssocie}
                          onChange={(e) => setAffichConjointAssocie(e.target.value)}
                          options={[
                            { value: false, label: context.state.Prenom },
                            { value: true, label: context.state.PrenomConjoint },
                          ]}
                          normal
                        />
                      ) : (
                        "Prestations"
                      )}
                    </td>
                    <td>Avant</td>
                    <td>Après</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad className="blockRoundedForce">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>Retraite nette annuelle</td>
                    <td>{applyFormat(TotalRenteAnnuelleAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(TotalRenteAnnuelleApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Dont rentes obligatoires</td>
                    <td>{applyFormat(TotalRenteObligatoireAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(TotalRenteObligatoireApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Dont rentes facultatives</td>
                    <td>{applyFormat(TotalRenteFacultativeAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(TotalRenteFacultativeApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Dont capitaux fractionnés</td>
                    <td>{applyFormat(TotalCapitalFractionneAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(TotalCapitalFractionneApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad className="blockRoundedForce">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>IJ max</td>
                    <td>{applyFormat(IJMaxAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(IJMaxApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Invalidité totale</td>
                    <td>{applyFormat(InvaliditeTotaleAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(InvaliditeTotaleApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Décès</td>
                    <td>{applyFormat(CapitalDecesAvant, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td>{applyFormat(CapitalDecesApres, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>
        </Row>
      </Column>

      {/* MODAL OPTIMUM */}
      <Modal
        width="40%"
        height="50%"
        title="Optimum"
        icon={<FaGlobe />}
        visible={modalOptimum}
        onClose={() => {
          setModalOptimum(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row bpad>
                        <DropDown
                          label="Optimum"
                          value={context.state.OptiRemChoixOptimum}
                          onChange={context.handleInputChange("OptiRemChoixOptimum")}
                          options={[
                            { value: "automatique", label: "Maximiser le revenu disponible" },
                            { value: "manu", label: "Optimum avec rémunération minimale" },
                          ]}
                          large
                        />
                      </Row>
                      {context.state.OptiRemChoixOptimum === "manu" && (
                        <Row tpad>
                          <TextField
                            label="REMUNÉRATION NETTE MINIMALE"
                            value={context.state.OptiRemOptimumRemMini}
                            onChange={context.handleInputChange("OptiRemOptimumRemMini")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            large
                          />
                        </Row>
                      )}
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setModalOptimum(false);
                let elem = document.querySelector("#simulation");
                let rect = elem.getBoundingClientRect();
                setCircularWidth(rect.width + "px");
                context.handleValueChange("OptiRemLoading", true);
                context.handleCalculOptiRem("optimum", "", ClientConjoint, ResultatTNS, false, false);
              },
            },
          }}
        />
      </Modal>
      {/* MODAL CHANGEMENT STATUT */}
      <Modal
        width="50%"
        height="70%"
        title="Changement de statut"
        icon={<FaGlobe />}
        visible={modalChgtStatut}
        onClose={() => {
          setModalChgtStatut(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row bpad>
                        <blockquote>Par défaut, le logiciel raisonne à budget équivalent. Ce parti pris peut être modifié dans les champs de la nouvelle situation.</blockquote>
                      </Row>
                      {context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && (
                        <Row bpad>
                          <Column bpad>
                            <DropDown
                              label="Etude réalisé pour"
                              value={context.state.OptiRemCDSEtude}
                              options={[
                                {
                                  value: "client",
                                  label: context.state.Prenom,
                                },
                                {
                                  value: "conjoint",
                                  label: context.state.PrenomConjoint,
                                },
                                {
                                  value: "both",
                                  label: "Les deux",
                                },
                              ]}
                              onChange={context.handleInputChange("OptiRemCDSEtude")}
                              normal
                            />
                          </Column>
                        </Row>
                      )}
                      {(context.state.PeriodeActuelle[0]?.StatutConjoint === "Aucun" ||
                        (context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && context.state.OptiRemCDSEtude !== "conjoint")) && (
                        <>
                          <Row>
                            <h1>Changement de statut de {context.state["Prenom" + ClientConjoint]}</h1>
                          </Row>
                          <Row>
                            <Column>
                              <DropDown
                                label="Statut social"
                                value={context.state["OptiRemCDSStatut" + ClientConjoint]}
                                options={
                                  context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS Article 62"
                                    ? [
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : context.state["PeriodeActuelle" + ClientConjoint][0]?.Statut === "TNS individuel"
                                    ? [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                        {
                                          value: "TNS individuel",
                                          label: "TNS individuel",
                                        },
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                      ]
                                }
                                onChange={context.handleInputChange("OptiRemCDSStatut" + ClientConjoint)}
                                normal
                              />
                            </Column>
                          </Row>
                          {(context.state["OptiRemCDSStatut" + ClientConjoint] === "TNS Article 62" || context.state["OptiRemCDSStatut" + ClientConjoint] === "TNS individuel") && (
                            <Row tpad>
                              <Column>
                                <DropDown
                                  label="Caisse d'affiliation"
                                  value={context.state["OptiRemCDSCaisse" + ClientConjoint]}
                                  options={caisse}
                                  onChange={context.handleInputChange("OptiRemCDSCaisse" + ClientConjoint)}
                                  normal
                                />
                              </Column>
                              {context.state["OptiRemCDSStatut"] === "TNS individuel" && (
                                <Column>
                                  <DropDown
                                    label="MODE D'EXERCICE"
                                    value={"IS"}
                                    options={[
                                      {
                                        value: "IS",
                                        label: "En société à l'IS",
                                      },
                                    ]}
                                    // onChange={context.handleInputChange("OptiRemCDSCaisse")}
                                    disabled
                                    normal
                                  />
                                </Column>
                              )}
                            </Row>
                          )}
                          {context.state["OptiRemCDSStatut"] === "TNS individuel" && (
                            <Row tpad>
                              <Column>
                                <TextField
                                  label="% rémunération du mandat"
                                  value={context.state.OptiRemPartRemunerationMandat}
                                  onChange={context.handleInputChange("OptiRemPartRemunerationMandat")}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: "%" }}
                                  normal
                                />
                              </Column>
                            </Row>
                          )}
                          {(context.state["OptiRemCDSStatut" + ClientConjoint] === "TNS Article 62" || context.state["OptiRemCDSStatut" + ClientConjoint] === "TNS individuel") && (
                            <>
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Carcdsf" && <ParamCarcdsf Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Carcdsf2" && <ParamCarcdsf Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Carmf" && <ParamCarmf Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Carpimko" && <ParamCarpimko Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Carpv" && <ParamCarpv Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Cavec" && <ParamCavec Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Cavp" && <ParamCavp Outil="OptiRem" ClientConjoint="" />}
                              {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="" />} */}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Cnbf" && (
                                <>
                                  <ParamCnbf Outil="OptiRem" ClientConjoint={ClientConjoint} />
                                  <Row tpad>
                                    <Column>
                                      <ParamLpaPageModal Outil="OptiRem" ClientConjoint={ClientConjoint} />
                                    </Column>
                                  </Row>
                                </>
                              )}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Crn" && <ParamCrn Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                              {context.state["OptiRemCDSCaisse" + ClientConjoint] === "Msa" && <ParamMsa Outil="OptiRem" ClientConjoint={ClientConjoint} />}
                            </>
                          )}

                          {/* <Row tpad bpad>
                            <Column>
                              <DropDown
                                label="Raisonnement"
                                value={context.state.OptiRemCDSRaisonnement}
                                options={[
                                  {
                                    value: "cout_remuneration",
                                    label: "A budget équivalent",
                                  },
                                  {
                                    value: "remuneration_nette",
                                    label: "A rémunération nette équivalente",
                                  },
                                ]}
                                onChange={context.handleInputChange("OptiRemCDSRaisonnement")}
                                normal
                              />
                            </Column>
                          </Row> */}
                          {context.state["OptiRemCDSStatut" + ClientConjoint] === "Assimilé salarié" && (
                            <Row tpad bpad>
                              <ParamFicheDePaiePageModal Outil="OptiRem" ClientConjoint="" />
                            </Row>
                          )}
                        </>
                      )}

                      {context.state.PeriodeActuelle[0]?.StatutConjoint !== "Aucun" && context.state.OptiRemCDSEtude !== "client" && (
                        <>
                          <Row tpad>
                            <h1>Changement de statut de {context.state.PrenomConjoint}</h1>
                          </Row>
                          <Row>
                            <Column>
                              <DropDown
                                label="Statut social"
                                value={context.state.OptiRemCDSStatutConjoint}
                                options={
                                  context.state["PeriodeActuelle" + ClientConjoint][0]?.StatutConjoint === "TNS Article 62"
                                    ? [
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : context.state["PeriodeActuelle" + ClientConjoint][0]?.StatutConjoint === "TNS individuel"
                                    ? [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                        {
                                          value: "Assimilé salarié",
                                          label: "Assimilé salarié",
                                        },
                                      ]
                                    : [
                                        {
                                          value: "TNS Article 62",
                                          label: "TNS Article 62",
                                        },
                                      ]
                                }
                                onChange={context.handleInputChange("OptiRemCDSStatutConjoint")}
                                normal
                              />
                            </Column>
                          </Row>

                          {(context.state.OptiRemCDSStatutConjoint === "TNS Article 62" || context.state.OptiRemCDSStatutConjoint === "TNS individuel") && (
                            <>
                              {context.state.OptiRemCDSCaisse === "Carcdsf" && <ParamCarcdsf Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Carcdsf2" && <ParamCarcdsf Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Carmf" && <ParamCarmf Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Carpimko" && <ParamCarpimko Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Carpv" && <ParamCarpv Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Cavec" && <ParamCavec Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Cavp" && <ParamCavp Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {/* {context.state.Caisse === "Cipav" && <ParamCipav Outil="" ClientConjoint="" />} */}
                              {context.state.OptiRemCDSCaisse === "Cnbf" && (
                                <>
                                  <ParamCnbf Outil="OptiRem" ClientConjoint="Conjoint" />
                                  <Row tpad>
                                    <Column>
                                      <ParamLpaPageModal Outil="OptiRem" ClientConjoint="Conjoint" />
                                    </Column>
                                  </Row>
                                </>
                              )}
                              {context.state.OptiRemCDSCaisse === "Crn" && <ParamCrn Outil="OptiRem" ClientConjoint="Conjoint" />}
                              {context.state.OptiRemCDSCaisse === "Msa" && <ParamMsa Outil="OptiRem" ClientConjoint="Conjoint" />}
                            </>
                          )}

                          {/* <Row tpad bpad>
                            <Column>
                              <DropDown
                                label="Raisonnement"
                                value={context.state.OptiRemCDSRaisonnementConjoint}
                                options={[
                                  {
                                    value: "cout_remuneration",
                                    label: "A budget équivalent",
                                  },
                                  {
                                    value: "remuneration_nette",
                                    label: "A rémunération nette équivalente",
                                  },
                                ]}
                                onChange={context.handleInputChange("OptiRemCDSRaisonnementConjoint")}
                                normal
                              />
                            </Column>
                          </Row> */}
                          {context.state.OptiRemCDSStatutConjoint === "Assimilé salarié" && (
                            <Row tpad>
                              <ParamFicheDePaiePageModal Outil="OptiRem" ClientConjoint="Conjoint" />
                            </Row>
                          )}
                        </>
                      )}
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setModalChgtStatut(false);
                context.handleChangementStatut(ClientConjoint, ResultatTNS);
              },
            },
          }}
        />
      </Modal>

      {/* MODAL DETAIL CLIENT */}
      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetail}
        onClose={() => {
          setModalDetail(false);
        }}
      >
        {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
          <DetailTNSPageModal
            Outil=""
            Prenom={Prenom}
            Caisse={Periode?.Caisse}
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerp}
            GraphDisponible={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].GraphDisponible}
            DetailCotisations={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailCotisations}
            DetailAssiette={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailAssiette}
            DetailCSG={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailCSG}
            TotalChargesSociales={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].TotalChargesSociales}
            DetailAssietteClient={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailAssiette}
            DetailAssietteConjoint={
              PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel"
                ? ResultatTNS.state.OptiRemRemunerationConjoint?.DetailAssiette
                : ResultatTNS.state.OptiRemRemunerationConjoint?.DetailFicheDePaie
            }
            TabIJ={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabIJ}
            GraphIJ={context.state["OptiRemResultatPrevoyance" + ClientConjoint].ImgGraphIncap}
            TabInval={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabInval}
            GraphInval={context.state["OptiRemResultatPrevoyance" + ClientConjoint].ImgGraphInval}
            TabDeces={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabDeces}
            TabRente={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabRente}
            InfosRetraiteChoixDepart={context.state["OptiRemResultatRetraite" + ClientConjoint]?.RetraiteChoixDepartRetraite}
            InfosRetraite={context.state["OptiRemResultatRetraite" + ClientConjoint]?.Retraite}
            ClientConjoint={ClientConjoint}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            Outil=""
            Prenom={Prenom}
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerp}
            DetailFicheDePaie={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailFicheDePaie}
            DetailAssietteClient={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].DetailFicheDePaie}
            DetailAssietteConjoint={
              PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel"
                ? ResultatTNS.state.OptiRemRemunerationConjoint.DetailAssiette
                : ResultatTNS.state.OptiRemRemunerationConjoint.DetailFicheDePaie
            }
            GraphDisponible={ResultatTNS.state["OptiRemRemuneration" + ClientConjoint].GraphDisponible}
            TabIJ={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabIJ}
            GraphIJ={context.state["OptiRemResultatPrevoyance" + ClientConjoint].ImgGraphIncap}
            TabInval={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabInval}
            GraphInval={context.state["OptiRemResultatPrevoyance" + ClientConjoint].ImgGraphInval}
            TabDeces={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabDeces}
            TabRente={context.state["OptiRemResultatPrevoyance" + ClientConjoint].TabRente}
            InfosRetraiteChoixDepart={context.state["OptiRemResultatRetraite" + ClientConjoint]?.RetraiteChoixDepartRetraite}
            InfosRetraite={context.state["OptiRemResultatRetraite" + ClientConjoint]?.Retraite}
            ClientConjoint={ClientConjoint}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        )}
      </Modal>
      {/* MODAL DETAIL CONJOINT */}
      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetailConjoint}
        onClose={() => {
          setModalDetailConjoint(false);
        }}
      >
        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel" ? (
          <DetailTNSPageModal
            Outil=""
            Prenom={context.state.PrenomConjoint}
            Caisse={Periode?.Caisse}
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerpConjoint}
            GraphDisponible={ResultatTNS.state.OptiRemRemunerationConjoint.GraphDisponible}
            DetailCotisations={ResultatTNS.state.OptiRemRemunerationConjoint.DetailCotisations}
            DetailAssiette={ResultatTNS.state.OptiRemRemunerationConjoint.DetailAssiette}
            DetailCSG={ResultatTNS.state.OptiRemRemunerationConjoint.DetailCSG}
            TotalChargesSociales={ResultatTNS.state.OptiRemRemunerationConjoint.TotalChargesSociales}
            DetailAssietteClient={
              Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                ? ResultatTNS.state.OptiRemRemuneration.DetailAssiette
                : ResultatTNS.state.OptiRemRemuneration.DetailFicheDePaie
            }
            DetailAssietteConjoint={ResultatTNS.state.OptiRemRemunerationConjoint.DetailAssiette}
            TabIJ={context.state.OptiRemResultatPrevoyanceConjoint?.TabIJ}
            GraphIJ={context.state.OptiRemResultatPrevoyanceConjoint?.ImgGraphIncap}
            TabInval={context.state.OptiRemResultatPrevoyanceConjoint?.TabInval}
            GraphInval={context.state.OptiRemResultatPrevoyanceConjoint?.ImgGraphInval}
            TabDeces={context.state.OptiRemResultatPrevoyanceConjoint?.TabDeces}
            TabRente={context.state.OptiRemResultatPrevoyanceConjoint?.TabRente}
            InfosRetraiteChoixDepart={context.state.OptiRemResultatRetraiteConjoint?.RetraiteChoixDepartRetraite}
            InfosRetraite={context.state.OptiRemResultatRetraiteConjoint?.Retraite}
            ClientConjoint="Conjoint"
            visible={modalDetailConjoint}
            onClose={() => {
              setModalDetailConjoint(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            Outil=""
            Prenom={context.state.PrenomConjoint}
            DetailImpot={ResultatTNS.state.OptiRemDetailImpot}
            DetailAssietteClient={
              Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                ? ResultatTNS.state.OptiRemRemuneration.DetailAssiette
                : ResultatTNS.state.OptiRemRemuneration.DetailFicheDePaie
            }
            DetailAssietteConjoint={ResultatTNS.state.OptiRemRemunerationConjoint.DetailFicheDePaie}
            GraphPerp={ResultatTNS.state.OptiRemDetailImpot?.GraphPerpConjoint}
            DetailFicheDePaie={ResultatTNS.state.OptiRemRemunerationConjoint.DetailFicheDePaie}
            GraphDisponible={ResultatTNS.state.OptiRemRemunerationConjoint.GraphDisponible}
            TabIJ={context.state.OptiRemResultatPrevoyanceConjoint?.TabIJ}
            GraphIJ={context.state.OptiRemResultatPrevoyanceConjoint?.ImgGraphIncap}
            TabInval={context.state.OptiRemResultatPrevoyanceConjoint?.TabInval}
            GraphInval={context.state.OptiRemResultatPrevoyanceConjoint?.ImgGraphInval}
            TabDeces={context.state.OptiRemResultatPrevoyanceConjoint?.TabDeces}
            TabRente={context.state.OptiRemResultatPrevoyanceConjoint?.TabRente}
            InfosRetraiteChoixDepart={context.state.OptiRemResultatRetraiteConjoint?.RetraiteChoixDepartRetraite}
            InfosRetraite={context.state.OptiRemResultatRetraiteConjoint?.Retraite}
            visible={modalDetailConjoint}
            onClose={() => {
              setModalDetailConjoint(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default OptiRemPage;
