import React, { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import StateBox from "components/ui/controls/state-box/state-box";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";

const InvaliditeRetraite = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  useEffect(() => {
    ResultatTNS.GetResultBilanRetraite(context.state, "").then((ResultatRetraite) => {});
    if (context.state.InclureConjoint === true) {
      ResultatTNS.GetResultBilanRetraite(context.state, "Conjoint").then((ResultatRetraite) => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row bpad>
              <Column>
                <blockquote>
                  Cet onglet vous permet de cocher les régimes pour lesquels vous souhaitez forcer le taux plein. Cela permet notamment de prévoir les cas de départ pour invalidité ou
                  inaptitude. Attention, le logiciel ne vérifie pas si les régimes listés ci dessous prévoient cette possibilité ou si les conditions relatives à ces dispositifs sont
                  remplies. Il vous appartient donc de vérifier ces points et MAKO ne peut être tenu responsable d'une éventuelle erreur. Avant toute prise de décision, nous vous invitons à
                  contacter la caisse concernée.
                </blockquote>
              </Column>
            </Row>
            <Row>
              <h1>Régimes de base</h1>
            </Row>
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("RegimeUnifie") && (
              <Row>
                <Column>
                  <StateBox
                    label="Régime unifié"
                    inline
                    value={context.state["InvaliditeRegimeUnifie" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeRegimeUnifie" + ClientConjoint, !context.state["InvaliditeRegimeUnifie" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cnavpl") && (
              <Row>
                <Column>
                  <StateBox
                    label="CNAVPL"
                    inline
                    value={context.state["InvaliditeCnavpl" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCnavpl" + ClientConjoint, !context.state["InvaliditeCnavpl" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("FonctionPubliqueBase") && (
              <Row>
                <Column>
                  <StateBox
                    label="Fonction publique"
                    inline
                    value={context.state["InvaliditeFonctionPubliqueBase" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeFonctionPubliqueBase" + ClientConjoint, !context.state["InvaliditeFonctionPubliqueBase" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Crpcen") && (
              <Row>
                <Column>
                  <StateBox
                    label="CRPCEN"
                    inline
                    value={context.state["InvaliditeCrpcen" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCrpcen" + ClientConjoint, !context.state["InvaliditeCrpcen" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cnbf") && (
              <Row>
                <Column>
                  <StateBox
                    label="CNBF"
                    inline
                    value={context.state["InvaliditeCnbf" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCnbf" + ClientConjoint, !context.state["InvaliditeCnbf" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Msa") && (
              <Row>
                <Column>
                  <StateBox
                    label="MSA"
                    inline
                    value={context.state["InvaliditeMsa" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeMsa" + ClientConjoint, !context.state["InvaliditeMsa" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            <Row tpad>
              <h1>Régimes complémentaires</h1>
            </Row>
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Salarie") && (
              <Row>
                <Column>
                  <StateBox
                    label="AGIRC-ARRCO"
                    inline
                    value={context.state["InvaliditeAgircArrco" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeAgircArrco" + ClientConjoint, !context.state["InvaliditeAgircArrco" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Ircantec") && (
              <Row>
                <Column>
                  <StateBox
                    label="IRCANTEC"
                    inline
                    value={context.state["InvaliditeIrcantec" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeIrcantec" + ClientConjoint, !context.state["InvaliditeIrcantec" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("RsiArtisan") ||
              ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("RsiCommercant") ||
              ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("RCI")) && (
              <Row>
                <Column>
                  <StateBox
                    label="RCI"
                    inline
                    value={context.state["InvaliditeRCI" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeRCI" + ClientConjoint, !context.state["InvaliditeRCI" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Ravgdt") && (
              <Row>
                <Column>
                  <StateBox
                    label="RAVGDT"
                    inline
                    value={context.state["InvaliditeRavgdt" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeRavgdt" + ClientConjoint, !context.state["InvaliditeRavgdt" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cipav") && (
              <Row>
                <Column>
                  <StateBox
                    label="CIPAV"
                    inline
                    value={context.state["InvaliditeCipav" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCipav" + ClientConjoint, !context.state["InvaliditeCipav" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cavec") && (
              <Row>
                <Column>
                  <StateBox
                    label="CAVEC"
                    inline
                    value={context.state["InvaliditeCavec" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCavec" + ClientConjoint, !context.state["InvaliditeCavec" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cnbf") && (
              <Row>
                <Column>
                  <StateBox
                    label="CNBF"
                    inline
                    value={context.state["InvaliditeCnbfComp" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCnbfComp" + ClientConjoint, !context.state["InvaliditeCnbfComp" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}

            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cavom") && (
              <Row>
                <Column>
                  <StateBox
                    label="CAVOM"
                    inline
                    value={context.state["InvaliditeCavom" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCavom" + ClientConjoint, !context.state["InvaliditeCavom" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Crn") && (
              <Row>
                <Column>
                  <StateBox
                    label="CRN"
                    inline
                    value={context.state["InvaliditeCrn" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCrn" + ClientConjoint, !context.state["InvaliditeCrn" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cavamac") && (
              <Row>
                <Column>
                  <StateBox
                    label="CAVAMAC"
                    inline
                    value={context.state["InvaliditeCavamac" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCavamac" + ClientConjoint, !context.state["InvaliditeCavamac" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carpv") && (
              <Row>
                <Column>
                  <StateBox
                    label="CARPV"
                    inline
                    value={context.state["InvaliditeCarpv" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCarpv" + ClientConjoint, !context.state["InvaliditeCarpv" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carpimko") && (
              <Row>
                <Column>
                  <StateBox
                    label="CARPIMKO"
                    inline
                    value={context.state["InvaliditeCarpimko" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCarpimko" + ClientConjoint, !context.state["InvaliditeCarpimko" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carcdsf") && (
              <Row>
                <Column>
                  <StateBox
                    label="CARCD"
                    inline
                    value={context.state["InvaliditeCarcdsf" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCarcdsf" + ClientConjoint, !context.state["InvaliditeCarcdsf" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carcdsf2") && (
              <Row>
                <Column>
                  <StateBox
                    label="CARSF"
                    inline
                    value={context.state["InvaliditeCarcdsf2" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCarcdsf2" + ClientConjoint, !context.state["InvaliditeCarcdsf2" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}

            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cavp") && (
              <Row>
                <Column>
                  <StateBox
                    label="CAVP"
                    inline
                    value={context.state["InvaliditeCavp" + ClientConjoint]}
                    onClick={() => context.handleValueChange("InvaliditeCavp" + ClientConjoint, !context.state["InvaliditeCavp" + ClientConjoint])}
                    normal
                  />
                </Column>
              </Row>
            )}

            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalPensionSupplementaire > 0 && (
              <Row tpad>
                <h1>Régimes supplémentaires</h1>
              </Row>
            )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carcdsf") &&
              (ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointAvant1995"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPoint19952005"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf"]["General"]["AsvTotalPointApres2006"] > 0) && (
                <Row>
                  <Column>
                    <StateBox
                      label="CARCD"
                      inline
                      value={context.state["InvaliditeCarcdsfAsv" + ClientConjoint]}
                      onClick={() => context.handleValueChange("InvaliditeCarcdsfAsv" + ClientConjoint, !context.state["InvaliditeCarcdsfAsv" + ClientConjoint])}
                      normal
                    />
                  </Column>
                </Row>
              )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carcdsf2") &&
              (ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointAvant1995"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPoint19952005"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carcdsf2"]["General"]["AsvTotalPointApres2006"] > 0) && (
                <Row>
                  <Column>
                    <StateBox
                      label="CARSF"
                      inline
                      value={context.state["InvaliditeCarcdsf2Asv" + ClientConjoint]}
                      onClick={() => context.handleValueChange("InvaliditeCarcdsf2Asv" + ClientConjoint, !context.state["InvaliditeCarcdsf2Asv" + ClientConjoint])}
                      normal
                    />
                  </Column>
                </Row>
              )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carmf") &&
              ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carmf"]["General"]["TotalPointAsv"] > 0 && (
                <Row>
                  <Column>
                    <StateBox
                      label="CARMF"
                      inline
                      value={context.state["InvaliditeCarmfAsv" + ClientConjoint]}
                      onClick={() => context.handleValueChange("InvaliditeCarmfAsv" + ClientConjoint, !context.state["InvaliditeCarmfAsv" + ClientConjoint])}
                      normal
                    />
                  </Column>
                </Row>
              )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Carpimko") &&
              (ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19761987"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19881997"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carpimko"]["General"]["AsvTotalPoint19982005"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Carpimko"]["General"]["AsvTotalPointApres2005"] > 0) && (
                <Row>
                  <Column>
                    <StateBox
                      label="CARPIMKO"
                      inline
                      value={context.state["InvaliditeCapimkoAsv" + ClientConjoint]}
                      onClick={() => context.handleValueChange("InvaliditeCapimkoAsv" + ClientConjoint, !context.state["InvaliditeCapimkoAsv" + ClientConjoint])}
                      normal
                    />
                  </Column>
                </Row>
              )}
            {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite?.hasOwnProperty("Cavp") &&
              (ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19821986"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19871991"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19921996"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint19972001"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPoint20022005"] > 0 ||
                ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.InfosRetraite["Cavp"]["General"]["AsvTotalPointAPartir2024"] > 0) && (
                <Row>
                  <Column>
                    <StateBox
                      label="CAVP"
                      inline
                      value={context.state["InvaliditeCavpAsv" + ClientConjoint]}
                      onClick={() => context.handleValueChange("InvaliditeCavpAsv" + ClientConjoint, !context.state["InvaliditeCavpAsv" + ClientConjoint])}
                      normal
                    />
                  </Column>
                </Row>
              )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default InvaliditeRetraite;
