import "./App.css";
import "./themes/default.css";
import "./themes/plpsoft.css";
import "./themes/kwiper.css";
import Footer from "./components/ui/layout/footer/footer";
import MainMenu from "./components/ui/layout/main-menu/main-menu";
import Header from "./components/ui/layout/header/header";
import MainPager from "./pages/main-pager";
import { AppUIContext, AppUIContextProvider } from "./providers/app-ui-context";
import MainContent from "./components/ui/layout/main-content/main-content";
import Content from "./components/ui/layout/content/content";
import AuthProvider, { AuthContext } from "./components/auth/auth-provider";
import Button from "./components/ui/controls/button/button";
import { FaAtom, FaCog, FaCogs, FaFolder, FaGlobe, FaVideo } from "react-icons/fa";
import Settings from "./components/app/settings/settings";
import { createRef, useContext, useEffect, useState } from "react";
import RemExpertProvider, { AppContext } from "components/app/RemExprertProvider";
import AuditsPage from "pages/extranet/audits pager";
import AuditCompletPage from "pages/audit complet";
import AuditRetraitePage from "pages/audit retraite";
import AuditRemuPage from "pages/audit rémunération";
import AuditPrevPage from "pages/audit prévoyance";
import ClientsPage from "pages/extranet/clients pager";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Dialogs from "components/ui/dialogs/dialogs";
import AdminPage from "pages/extranet/admin";
import queryString from "query-string";
import AuthProviderApi, { AuthContextApi } from "components/auth/auth-provider-api";
import { OpenDossier } from "components/app/Api/ApiPartner";
import HeaderKwiper from "components/ui/layout/header/header-kwiper";
// import ChangerMDP from './pages/identification/changer-mdp/changer-mdp';

/**
 * Application component.
 * @returns Application component.
 */
const AppApiUserKWIPER = (props) => {
  // Initialiser les états
  const [preferencesVisibles, definirPreferencesVisibles] = useState(false);
  const [accessAdmin, setAccessAdmin] = useState(false);
  const [mentionLegale, setMentionLegale] = useState(false);
  const [logoName, setLogoName] = useState(false);
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [infosClient, setInfosClient] = useState(false);
  const [infosDossier, setInfosDossier] = useState({});
  const [isLogged, setIsLogged] = useState(false);
  const context = useContext(AppContext);
  const mainContent = createRef();
  const [tempo, setTempo] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleAccessDossier = () => {
    setAccessAdmin(false);
  };
  const Params = queryString.parse(window.location.search);
  // console.log('PARAMS',Params);
  useEffect(() => {
    OpenDossier(Params.IdDossier).then((Dossier) => {
      if (Dossier.InfosClient.AuthKey === process.env["REACT_APP_PARTNERS_" + Params.Partner + "_APIKEY"]) {
        setIsLogged(true);
        setMentionLegale(Dossier.InfosClient.RapportMentionLegale);
        setLogoName(Dossier.InfosClient.RapportLogo);
        setColor1(Dossier.InfosClient.RapportColors1);
        setColor2(Dossier.InfosClient.RapportColors2);
        setInfosClient(Dossier.InfosClient);
        // console.log(Dossier);
        setInfosDossier(Dossier.InfosDossier);
      }
    });

    let dimensions={ width: 0, height: 0 }
    setTempo(
      setInterval(() => {
        const elements = document.querySelectorAll(".pager-content .col");
       
        if (!elements.length) return;

        const rect = elements[0].getBoundingClientRect();
        const mainContent=document.querySelector('.main-content');
        const rect2 =mainContent.getBoundingClientRect();
        mainContent.setAttribute('style', 'height:fit-content !important');
        if ((rect2.width === dimensions.width) && (rect2.height === dimensions.height)) return;

        let evt = {
          action: "resize",
          height: rect2.height,
          width: rect2.width,
        };
        dimensions={width:rect2.width,height:rect2.height};
        console.log('DIM',dimensions)
        window.top.postMessage(evt, "*");
      }, 100)
    );

    return () => {
      clearInterval(tempo);
    };

    // const resizeHandler = (evt, t) => {
    //   let e = {
    //     action: t || "resize",
    //     height: document.body.scrollHeight,
    //     width: document.body.scrollWidth,
    //   };
    //   window.top.postMessage(e, "*");
    // };
    // window.addEventListener("resize", resizeHandler);
    // resizeHandler(null, "ready");
    // return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <div ref={mainContent}>
      {isLogged && (
        <RemExpertProvider InfosUser={{ ApiUser: true }} InfosDossier={infosDossier}>
          <AppContext.Consumer>
            {(context) => (
              <AppUIContextProvider name=" " slogan=" " version="3.0.1">
                <AppUIContext.Consumer>
                  {(ui) => (
                    <div className={"App " + props.theme}>
                      <AuthProviderApi authApiUrl="login">
                        <AuthContextApi.Consumer>
                          {(auth) => (
                            <>
                              <div style={{ zIndex: "999999" }} className={"modal-bg" + (context.state.v2 === undefined ? " hidden" : context.state.v2 === false ? " hidden" : "")}>
                                <div style={{ height: "250px", width: "450px" }} className="modal">
                                  <Row className="modal-header">
                                    <Column className="modal-icon">
                                      <FaGlobe />
                                    </Column>
                                  </Row>
                                  <Row className="modal-content" fill>
                                    <Column fill>
                                      <Row fill center>
                                        <Column fill center>
                                          Mise à niveau du dossier.
                                        </Column>
                                      </Row>
                                    </Column>
                                  </Row>
                                </div>
                              </div>
                              <MainContent>
                                <HeaderKwiper
                                  authInfos={{
                                    utilisateur_reel: { id: 9999, prenom: "", nom: "" },
                                    cabinet: { id: 0 },
                                    preferences: {
                                      mentions: mentionLegale,
                                      couleurs: [color1.slice(0, 1) === "#" ? color1 : "#" + color1, color2.slice(0, 1) === "#" ? color2 : "#" + color2],
                                      logo: logoName,
                                    },
                                  }}
                                  Params={Params}
                                  InfosClient={infosClient}
                                  userId={9999}
                                  gender={0}
                                  firstName={"KWIPER"}
                                  lastName={"KWIPER"}
                                  subUserId={0}
                                  subGender={0}
                                  subFirstName={"KWIPER"}
                                  subLastName={"KWIPER"}
                                  company={"KWIPER"}
                                  association={"KWIPER"}
                                  onDisconnect={() => {
                                    auth.deconnecter();
                                    context.handleValueChange("AccesSimulateur", false);
                                  }}
                                  disconnectDisabled={false}
                                />
                                {accessAdmin === true ? (
                                  <Content>
                                    <AdminPage AccessDossier={handleAccessDossier} />
                                  </Content>
                                ) : (
                                  <Content>
                                    {/* <ChangerMDP /> */}
                                    {/* {context.state.AccesSimulateur === true && context.state.TypeSimulation === "" && <AuditsPage />} */}
                                    {/* {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit complet" && <AuditCompletPage />} */}
                                    {Params.Simulation === "BilanRetraite" && <AuditRetraitePage />}
                                    {Params.Simulation === "RemExpress" && <AuditRemuPage />}
                                    {/* {context.state.AccesSimulateur === true && context.state.TypeSimulation === "Audit prévoyance" && <AuditPrevPage />} */}
                                    {/* {context.state.AccesSimulateur === false && <ClientsPage />} */}
                                  </Content>
                                )}
                                {/* <Footer /> */}
                              </MainContent>
                            </>
                          )}
                        </AuthContextApi.Consumer>
                      </AuthProviderApi>
                    </div>
                  )}
                </AppUIContext.Consumer>
              </AppUIContextProvider>
            )}
          </AppContext.Consumer>
        </RemExpertProvider>
      )}
    </div>
  );
};

export default AppApiUserKWIPER;
