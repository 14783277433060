import { useState, useEffect, useContext } from "react";
import { FaAward, FaCalculator, FaCalendar, FaChartArea, FaCogs, FaFileSignature, FaLandmark, FaUpload, FaWheelchair } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DateDepartRetraite from "components/app/AuditComplet/Retraite/Parametrage/DateDepart/DateDepartRetraite";
import MajorationEnfantRetraite from "components/app/AuditComplet/Retraite/Parametrage/Majorations/MajorationEnfantRetraite";
import TauxRemplacement from "components/app/AuditComplet/Retraite/Parametrage/TauxRemplacement/TauxRemplacement";
import PrelevementsSociaux from "components/app/AuditComplet/Retraite/Parametrage/PrelevementsSociaux/PrelevementSociaux";
import AjoutContratRetrPersoPageModal from "./ajout contrat retraite perso modal";
import PointRetraite from "components/app/AuditComplet/Retraite/Parametrage/Points/PointRetraite";
import LectureRG from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/LectureRG";
import { RGContext } from "components/app/AuditComplet/Retraite/Parametrage/ReleveRG/RGProvider";
import InvaliditeRetraite from "components/app/AuditComplet/Retraite/Parametrage/Invalidite/InvaliditeRetraite";

const ParamRetraitePageModal = (props) => {
  const context = useContext(AppContext);
  const RGCtx = useContext(RGContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.visible]);
  return (
    <>
      <Pager
      id="paramRetraitePager"
        withMenu
        pages={[
          {
            isTitle: true,
            title: "Paramètres",
            subTitle: "Paramètres de la retraite",
          },
          {
            label: "Date de départ",
            icon: <FaCalendar />,
            content: <DateDepartRetraite Outil="" ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Points",
            icon: <FaCalculator />,
            content: <PointRetraite Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
       
          {
            label: "Majorations",
            icon: <FaAward />,
            content: <MajorationEnfantRetraite Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
          {
            label: "Forcer le taux plein",
            icon: <FaCogs />,
            content: <InvaliditeRetraite Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
          {
            label: "Import RG",
            icon: <FaUpload />,
            content: <LectureRG Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
          {
            label: "Taux de remplacement",
            icon: <FaChartArea />,
            content: <TauxRemplacement Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
          {
            label: "Prélèvements sociaux",
            icon: <FaLandmark />,
            content: <PrelevementsSociaux Outil="" ClientConjoint={props.ClientConjoint}/>,
          },
          {
            label: "Contrats",
            icon: <FaFileSignature id="contratFacPerso" />,
            content: <AjoutContratRetrPersoPageModal Outil="" ClientConjoint={props.ClientConjoint} AccessRemExpress={false}/>,
          },
        ]}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          // cancel: {},
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              if(RGCtx.Carriere.length>0){
                context.handleAddReleveRG(RGCtx.Carriere,props.ClientConjoint);
              }else{

                context.GetDateDepartRetraite(props.ClientConjoint,context.state);
              }
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default ParamRetraitePageModal;
