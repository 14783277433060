import "./resultat.css";
import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaCog, FaCogs, FaFilePowerpoint, FaFileWord, FaIdBadge, FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import RemunerationTNS from "./modules/remunerationTNS";
import RemunerationSalarie from "./modules/RemunerationSalarie";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import DetailTNSPageModal from "./detail/rémuneration/detailTNSPager";
import DetailSalariePageModal from "./detail/rémuneration/detailSalariePager";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { AuditCompletPptx, AuditCompletWord } from "components/app/Api/Rapport/ApiRapport";
import { Menu, MenuItem } from "@mui/material";

const ResultatPage = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);
  const [ClientConjoint, setClientConjoint] = useState("");
  const [modalDetail, setModalDetail] = useState(false);
  const [modalDetailConjoint, setModalDetailConjoint] = useState(false);
  const PeriodeClient = context.state["PeriodeActuelle"][0];
  const Periode = context.state["PeriodeActuelle" + ClientConjoint][0];
  const PeriodeConjoint = context.state["PeriodeActuelleConjoint"][0];
  const Prenom = context.state["Prenom" + ClientConjoint];
  const [AffichConjointAssocie, setAffichConjointAssocie] = useState(false);
  const [brutImposableClient, setBrutImposableClient] = useState(false);
  const [goForClient, setGoForClient] = useState(false);
  const [brutImposableConjoint, setBrutImposableConjoint] = useState(0);
  const [goForConjointAssocie, setGoForConjointAssocie] = useState(false);
  const [goForConjoint, setGoForConjoint] = useState(false);

  useEffect(() => {
    if (PeriodeClient !== undefined) {
      if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, ClientConjoint).then((brutImposable) => {
          setBrutImposableClient(Number(brutImposable));
          setGoForClient(true);
        });
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, ClientConjoint).then((brutImposable) => {
          setBrutImposableClient(Number(brutImposable));
          setGoForClient(true);
        });
      }

      if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
        setAffichConjointAssocie(true);
        if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
          ResultatTNS.GetResultRemExpert(context.state, "ConjointAssocie").then((brutImposable) => {
            setBrutImposableConjoint(brutImposable);
            setGoForConjointAssocie(true);
          });
        } else {
          ResultatSalarie.GetResultRemExpert(context.state, "ConjointAssocie").then((brutImposable) => {
            setBrutImposableConjoint(brutImposable);
            setGoForConjointAssocie(true);
          });
        }
      } else {
        setGoForConjointAssocie(true);
        setAffichConjointAssocie(false);
      }
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        ResultatTNS.GetResultRemExpert(context.state, "Conjoint").then((brutImposable) => {
          setBrutImposableConjoint(brutImposable);
          setGoForConjoint(true);
        });
      } else {
        ResultatSalarie.GetResultRemExpert(context.state, "Conjoint").then((brutImposable) => {
          setBrutImposableConjoint(brutImposable);
          setGoForConjoint(true);
        });
      }
    } else {
      setGoForConjoint(true);
    }
  }, [ClientConjoint, context.state.AgeDepartProjection, context.state.AgeDepartProjectionConjoint]);

  useEffect(() => {
    if (goForClient === true && goForConjoint === true && goForConjointAssocie === true) {
      if (Periode !== undefined) {
        const DividendeClient = PeriodeClient && PeriodeClient.DividendeBrut ? PeriodeClient.DividendeBrut : 0;
        const DividendeConjoint = PeriodeConjoint && PeriodeConjoint.DividendeBrut ? PeriodeConjoint.DividendeBrut : 0;
        ResultatTNS.GetImpot("", context.state, brutImposableClient, brutImposableConjoint, Number(DividendeClient) + Number(DividendeConjoint)).then(() => {});
      }
    }
  }, [goForClient, goForConjoint, goForConjointAssocie]);

  const handleGetEnvEntreprise = () => {
    let EnvEntr = 0;
    if (Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel") {
      EnvEntr += ResultatTNS.state["RemunerationTNS" + ClientConjoint].CoutRemuneration + ResultatTNS.state["RemunerationTNS" + ClientConjoint].CotisationsFacultativesFraisPro;
    } else {
      EnvEntr += ResultatSalarie.state["RemunerationSalarie" + ClientConjoint].CoutRemuneration;
    }

    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        EnvEntr += ResultatTNS.state.RemunerationTNSConjoint.CoutRemuneration + ResultatTNS.state.RemunerationTNSConjoint.CotisationsFacultativesFraisPro;
      } else {
        EnvEntr += ResultatSalarie.state.RemunerationSalarieConjoint.CoutRemuneration;
      }
    }
    EnvEntr += Periode?.ResAvtIs;
    return EnvEntr;
  };
  const handleRevenusPercus = () => {
    let RevPercus = 0;
    if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
      RevPercus += Number(ResultatTNS.state["RemunerationTNS"].RemunerationNette);
    } else {
      RevPercus += Number(ResultatSalarie.state["RemunerationSalarie"].SalaireNet);
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        RevPercus += Number(ResultatTNS.state["RemunerationTNSConjoint"].RemunerationNette);
      } else {
        RevPercus += Number(ResultatSalarie.state["RemunerationSalarieConjoint"].SalaireNet);
      }
    }
    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        RevPercus += Number(ResultatTNS.state.RemunerationTNSConjoint.RemunerationNette);
      } else {
        RevPercus += Number(ResultatSalarie.state.RemunerationSalarieConjoint.SalaireNet);
      }
    }
    const DividendeClient = PeriodeClient && PeriodeClient.DividendeBrut ? PeriodeClient.DividendeBrut : 0;
    const DividendeConjoint = PeriodeConjoint && PeriodeConjoint.DividendeBrut ? PeriodeConjoint.DividendeBrut : 0;

    RevPercus += Number(DividendeClient) + Number(DividendeConjoint);
    return RevPercus;
  };

  const handleCotisationsFacPerso = () => {
    let CotFacPerso = 0;
    if (PeriodeClient?.Statut === "TNS Article 62" || PeriodeClient?.Statut === "TNS individuel") {
      CotFacPerso += Number(ResultatTNS.state["RemunerationTNS" ].VersementFacultatifPerso);
    } else {
      CotFacPerso += Number(ResultatSalarie.state["RemunerationSalarie"].VersementFacultatifPerso);
    }
    if (context.state.InclureConjoint === true && PeriodeClient?.StatutConjoint !== "Aucun" && ClientConjoint === "") {
      if (PeriodeClient?.StatutConjoint === "TNS Article 62" || PeriodeClient?.StatutConjoint === "TNS individuel") {
        CotFacPerso += Number(ResultatTNS.state.RemunerationTNSConjoint.VersementFacultatifPerso);
      } else {
        CotFacPerso += Number(ResultatSalarie.state.RemunerationSalarieConjoint.VersementFacultatifPerso);
      }
    }
    if (PeriodeConjoint !== undefined) {
      if (PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel") {
        CotFacPerso += Number(ResultatTNS.state["RemunerationTNSConjoint"].VersementFacultatifPerso);
      } else {
        CotFacPerso += Number(ResultatSalarie.state["RemunerationSalarieConjoint"].VersementFacultatifPerso);
      }
    }

    return CotFacPerso;
  };

  const handleRevenusDispo = () => {
    return handleRevenusPercus() + ResultatTNS.state.DetailImpot?.AutresRevenusFoyer - handleCotisationsFacPerso() - ResultatTNS.state.DetailImpot?.IRTotal;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!Periode) {
    return;
  }

  return (
    <>
      <Column fill>
        {((context.state.InclureConjoint === true && Periode?.StatutConjoint === "Aucun" && PeriodeConjoint) || ClientConjoint === "Conjoint") && (
          <Row>
            <Column fill style={{ maxWidth: "1256px" }}>
              <CardBox bpad style={{ padding: "4px" }}>
                <table>
                  <tr>
                    <td>Sélectionnez une situation professionnelle</td>
                    <td>
                      <DropDown
                        value={ClientConjoint}
                        onChange={(e) => setClientConjoint(e.target.value)}
                        options={[
                          { value: "", label: "Situation professionnelle pour " + context.state.Prenom },
                          { value: "Conjoint", label: "Situation professionnelle pour " + context.state.PrenomConjoint },
                        ]}
                        xlarge
                      />
                    </td>
                  </tr>
                </table>
              </CardBox>
            </Column>
          </Row>
        )}
        <Row>
          <Column fill rpad style={{ maxWidth: "640px" }}>
            <CardBox bpad className="dark-title">
              <table className="resultat">
                <tbody>
                  <tr>
                    <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>Enveloppe entreprise</td>
                    <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>{applyFormat(handleGetEnvEntreprise(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
              <RemunerationTNS Resultat={ResultatTNS.state["RemunerationTNS" + ClientConjoint]} Prenom={Prenom} />
            ) : (
              <RemunerationSalarie Resultat={ResultatSalarie.state["RemunerationSalarie" + ClientConjoint]} Prenom={Prenom} />
            )}

            {AffichConjointAssocie === true &&
              (Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel" ? (
                <RemunerationTNS Resultat={ResultatTNS.state.RemunerationTNSConjoint} Prenom={context.state.PrenomConjoint} />
              ) : (
                <RemunerationSalarie Resultat={ResultatSalarie.state.RemunerationSalarieConjoint} Prenom={context.state.PrenomConjoint} />
              ))}
            {(Periode?.Statut !== "TNS individuel" || Periode?.ModeExercice === "IS") && (
              <CardBox tpad bpad>
                <table className="resultat">
                  <tbody>
                    <tr>
                      <td>Résultat avant IS</td>
                      <td>{applyFormat(Periode?.ResAvtIs, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Impôt sur les sociétés</td>
                      <td>{applyFormat(Periode?.ImpotSoc, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Mise en réserve{Periode?.DividendeBrutAutres !== 0 ? " / div. autres associés" : ""}</td>
                      <td>{applyFormat(Number(Periode?.Reserve) + Number(Periode?.DividendeBrutAutres), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                    <tr>
                      <td>Dividendes bruts</td>
                      <td>{applyFormat(Periode?.DividendeBrut, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    </tr>
                  </tbody>
                </table>
              </CardBox>
            )}
            <CardBox tpad bpad>
              <table className="resultat">
                <tbody>
                  <tr>
                    <td>Revenus d'activités perçus</td>
                    <td>{applyFormat(handleRevenusPercus(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Autres revenus du foyer</td>
                    <td>{applyFormat(ResultatTNS.state.DetailImpot?.AutresRevenusFoyer, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Impôt sur le revenu</td>
                    <td>{applyFormat(ResultatTNS.state.DetailImpot?.IRTotal, "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                  <tr>
                    <td>Cotisations facultatives personnelles</td>
                    <td>{applyFormat(handleCotisationsFacPerso(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad className="dark-title">
              <table className="resultat">
                <tbody>
                  <tr>
                    <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>Revenu disponible</td>
                    <td style={{ paddingTop: "2px", paddingBottom: "2px" }}>{applyFormat(handleRevenusDispo(), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>
          <Column fill lpad style={{ maxWidth: "600px" }}>
            <CardBox bpad>
              <Row>
                <Column></Column>
                <Column>
                  <Button onClick={handleClick}>
                    <FaPrint />
                    <span>Imprimer</span>
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        AuditCompletPptx(context.state, ResultatTNS.state, ResultatSalarie.state).then((res) => {});
                        handleClose();
                      }}
                      disableRipple
                    >
                      <FaFilePowerpoint style={{ color: "#cc4424", marginRight: "10px" }} sy />
                      <div style={{ paddingTop: "4px" }}> Etude simplifiée </div>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        AuditCompletWord(context.state, ResultatTNS.state, ResultatSalarie.state).then((res) => {});
                        handleClose();
                      }}
                      disableRipple
                    >
                      <FaFileWord style={{ color: "#2c5698", marginRight: "10px" }} sy />
                      <div style={{ paddingTop: "4px" }}> Etude complète </div>
                    </MenuItem>
                  </Menu>
                </Column>
                <Column>
                  <Button
                    onClick={() => {
                      setModalDetail(true);
                    }}
                  >
                    <FaCalculator />
                    <span>Détails {Prenom}</span>
                  </Button>
                </Column>
                {context.state.InclureConjoint === true && Periode?.StatutConjoint !== "Aucun" && ClientConjoint === "" ? (
                  <Column>
                    <Button
                      onClick={() => {
                        setModalDetailConjoint(true);
                      }}
                    >
                      <FaCalculator />
                      <span>Détails {context.state.PrenomConjoint}</span>
                    </Button>
                  </Column>
                ) : (
                  <></>
                )}
                <Column>
                  <Button
                    onClick={() => {
                      props.GoToSimulation();
                    }}
                  >
                    <FaCog />
                    <span>Simulation</span>
                  </Button>
                </Column>

              
                <Column></Column>
              </Row>
            </CardBox>
            <CardBox tpad bpad className="dark-title">
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>Prestations </td>
                    <td className="">{Prenom}</td>
                    {AffichConjointAssocie === true && <td>{context.state.PrenomConjoint}</td>}
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad>
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>Retraite nette annuelle</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(
                            ResultatTNS.state["Retraite" + ClientConjoint].TotalRetraiteNetteChoixDepartRetraite +
                              ResultatTNS.state["Retraite" + ClientConjoint].TotalCapitalFractionneChoixDepartRetraite,
                            "uint",
                            { thousands: " ", decimal: ",", unit: " €" }
                          )
                        : applyFormat(
                            ResultatSalarie.state["Retraite" + ClientConjoint].TotalRetraiteNetteChoixDepartRetraite +
                              ResultatSalarie.state["Retraite" + ClientConjoint].TotalCapitalFractionneChoixDepartRetraite,
                            "uint",
                            { thousands: " ", decimal: ",", unit: " €" }
                          )}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(
                              ResultatTNS.state.RetraiteConjoint.TotalRetraiteNetteChoixDepartRetraite + ResultatTNS.state.RetraiteConjoint.TotalCapitalFractionneChoixDepartRetraite,
                              "uint",
                              { thousands: " ", decimal: ",", unit: " €" }
                            )
                          : applyFormat(
                              ResultatSalarie.state.RetraiteConjoint.TotalRetraiteNetteChoixDepartRetraite + ResultatSalarie.state.RetraiteConjoint.TotalCapitalFractionneChoixDepartRetraite,
                              "uint",
                              { thousands: " ", decimal: ",", unit: " €" }
                            )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Dont rentes obligatoires</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalPensionNette, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalPensionNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalPensionNette, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalPensionNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Dont rentes facultatives</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalRenteNette, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalRenteNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalRenteNette, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalRenteNette, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Dont capitaux fractionnés</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalCapitalFractionne, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart.TotalCapitalFractionne, "uint", {
                            thousands: " ",
                            decimal: ",",
                            unit: " €",
                          })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalCapitalFractionne, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.RetraiteConjoint.InfosRetraiteChoixDepart.TotalCapitalFractionne, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </CardBox>
            <CardBox tpad bpad>
              <table className="prestas">
                <tbody>
                  <tr>
                    <td>IJ max</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["PrevTNS" + ClientConjoint].IJMax, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["PrevSalarie" + ClientConjoint].IJMax, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.PrevTNSConjoint.IJMax, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.PrevSalarieConjoint.IJMax, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Invalidité totale</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["PrevTNS" + ClientConjoint].InvaliditeTotale, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["PrevSalarie" + ClientConjoint].InvaliditeTotale, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.PrevTNSConjoint.InvaliditeTotale, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.PrevSalarieConjoint.InvaliditeTotale, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Décès</td>
                    <td>
                      {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                        ? applyFormat(ResultatTNS.state["PrevTNS" + ClientConjoint].CapitalDeces, "uint", { thousands: " ", decimal: ",", unit: " €" })
                        : applyFormat(ResultatSalarie.state["PrevSalarie" + ClientConjoint].CapitalDeces, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                    </td>
                    {AffichConjointAssocie === true && (
                      <td>
                        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel"
                          ? applyFormat(ResultatTNS.state.PrevTNSConjoint.CapitalDeces, "uint", { thousands: " ", decimal: ",", unit: " €" })
                          : applyFormat(ResultatSalarie.state.PrevSalarieConjoint.CapitalDeces, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>
        </Row>
      </Column>

      {/* MODAL DETAIL CLIENT */}
      <Modal
        width="80%"
        height="80%"
        title="Détails"
        icon={<FaIdBadge />}
        visible={modalDetail}
        onClose={() => {
          setModalDetail(false);
        }}
      >
        {Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel" ? (
          <DetailTNSPageModal
            Outil=""
            Prenom={Prenom}
            Caisse={Periode?.Caisse}
            DetailImpot={ResultatTNS.state.DetailImpot}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerp}
            GraphDisponible={ResultatTNS.state["RemunerationTNS" + ClientConjoint].GraphDisponible}
            DetailCotisations={ResultatTNS.state["RemunerationTNS" + ClientConjoint].DetailCotisations}
            DetailAssiette={ResultatTNS.state["RemunerationTNS" + ClientConjoint].DetailAssiette}
            DetailCSG={ResultatTNS.state["RemunerationTNS" + ClientConjoint].DetailCSG}
            TotalChargesSociales={ResultatTNS.state["RemunerationTNS" + ClientConjoint].TotalChargesSociales}
            DetailAssietteClient={ResultatTNS.state["RemunerationTNS" + ClientConjoint].DetailAssiette}
            DetailAssietteConjoint={
              PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel"
                ? ResultatTNS.state.RemunerationTNSConjoint.DetailAssiette
                : ResultatSalarie.state.RemunerationSalarieConjoint.DetailFicheDePaie
            }
            TabIJ={ResultatTNS.state["PrevTNS" + ClientConjoint].TabIJ}
            GraphIJ={ResultatTNS.state["PrevTNS" + ClientConjoint].ImgGraphIncap}
            TabInval={ResultatTNS.state["PrevTNS" + ClientConjoint].TabInval}
            GraphInval={ResultatTNS.state["PrevTNS" + ClientConjoint].ImgGraphInval}
            TabDeces={ResultatTNS.state["PrevTNS" + ClientConjoint].TabDeces}
            TabRente={ResultatTNS.state["PrevTNS" + ClientConjoint].TabRente}
            InfosRetraiteChoixDepart={ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart}
            InfosRetraite={ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraite}
            ClientConjoint={ClientConjoint}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            Outil=""
            Prenom={Prenom}
            DetailImpot={ResultatTNS.state.DetailImpot}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerp}
            DetailFicheDePaie={ResultatSalarie.state["RemunerationSalarie" + ClientConjoint].DetailFicheDePaie}
            DetailAssietteClient={ResultatSalarie.state["RemunerationSalarie" + ClientConjoint].DetailFicheDePaie}
            DetailAssietteConjoint={
              PeriodeConjoint?.Statut === "TNS Article 62" || PeriodeConjoint?.Statut === "TNS individuel"
                ? ResultatTNS.state.RemunerationTNSConjoint.DetailAssiette
                : ResultatSalarie.state.RemunerationSalarieConjoint.DetailFicheDePaie
            }
            GraphDisponible={ResultatSalarie.state["RemunerationSalarie" + ClientConjoint].GraphDisponible}
            TabIJ={ResultatSalarie.state["PrevSalarie" + ClientConjoint].TabIJ}
            GraphIJ={ResultatSalarie.state["PrevSalarie" + ClientConjoint].ImgGraphIncap}
            TabInval={ResultatSalarie.state["PrevSalarie" + ClientConjoint].TabInval}
            GraphInval={ResultatSalarie.state["PrevSalarie" + ClientConjoint].ImgGraphInval}
            TabDeces={ResultatSalarie.state["PrevSalarie" + ClientConjoint].TabDeces}
            TabRente={ResultatSalarie.state["PrevSalarie" + ClientConjoint].TabRente}
            InfosRetraiteChoixDepart={ResultatSalarie.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart}
            InfosRetraite={ResultatSalarie.state["Retraite" + ClientConjoint].InfosRetraite}
            ClientConjoint={ClientConjoint}
            visible={modalDetail}
            onClose={() => {
              setModalDetail(false);
            }}
          />
        )}
      </Modal>

      {/* MODAL DETAIL CONJOINT */}
      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetailConjoint}
        onClose={() => {
          setModalDetailConjoint(false);
        }}
      >
        {Periode?.StatutConjoint === "TNS Article 62" || Periode?.StatutConjoint === "TNS individuel" ? (
          <DetailTNSPageModal
            Outil=""
            Prenom={context.state.PrenomConjoint}
            Caisse={Periode?.Caisse}
            DetailImpot={ResultatTNS.state.DetailImpot}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerpConjoint}
            GraphDisponible={ResultatTNS.state.RemunerationTNSConjoint.GraphDisponible}
            DetailCotisations={ResultatTNS.state.RemunerationTNSConjoint.DetailCotisations}
            DetailAssiette={ResultatTNS.state.RemunerationTNSConjoint.DetailAssiette}
            DetailCSG={ResultatTNS.state.RemunerationTNSConjoint.DetailCSG}
            TotalChargesSociales={ResultatTNS.state.RemunerationTNSConjoint.TotalChargesSociales}
            DetailAssietteClient={
              Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                ? ResultatTNS.state.RemunerationTNS.DetailAssiette
                : ResultatSalarie.state.RemunerationSalarie.DetailFicheDePaie
            }
            DetailAssietteConjoint={ResultatTNS.state.RemunerationTNSConjoint.DetailAssiette}
            TabIJ={ResultatTNS.state.PrevTNSConjoint.TabIJ}
            GraphIJ={ResultatTNS.state.PrevTNSConjoint.ImgGraphIncap}
            TabInval={ResultatTNS.state.PrevTNSConjoint.TabInval}
            GraphInval={ResultatTNS.state.PrevTNSConjoint.ImgGraphInval}
            TabDeces={ResultatTNS.state.PrevTNSConjoint.TabDeces}
            TabRente={ResultatTNS.state.PrevTNSConjoint.TabRente}
            InfosRetraiteChoixDepart={ResultatTNS.state.RetraiteConjoint.InfosRetraiteChoixDepart}
            InfosRetraite={ResultatTNS.state.RetraiteConjoint.InfosRetraite}
            ClientConjoint="Conjoint"
            visible={modalDetailConjoint}
            onClose={() => {
              setModalDetailConjoint(false);
            }}
          />
        ) : (
          <DetailSalariePageModal
            Outil=""
            Prenom={context.state.PrenomConjoint}
            DetailImpot={ResultatTNS.state.DetailImpot}
            DetailAssietteClient={
              Periode?.Statut === "TNS Article 62" || Periode?.Statut === "TNS individuel"
                ? ResultatTNS.state.RemunerationTNS.DetailAssiette
                : ResultatSalarie.state.RemunerationSalarie.DetailFicheDePaie
            }
            DetailAssietteConjoint={ResultatSalarie.state.RemunerationSalarieConjoint.DetailFicheDePaie}
            GraphPerp={ResultatTNS.state.DetailImpot?.GraphPerpConjoint}
            DetailFicheDePaie={ResultatSalarie.state.RemunerationSalarieConjoint.DetailFicheDePaie}
            GraphDisponible={ResultatSalarie.state.RemunerationSalarieConjoint.GraphDisponible}
            TabIJ={ResultatSalarie.state.PrevSalarieConjoint.TabIJ}
            GraphIJ={ResultatSalarie.state.PrevSalarieConjoint.ImgGraphIncap}
            TabInval={ResultatSalarie.state.PrevSalarieConjoint.TabInval}
            GraphInval={ResultatSalarie.state.PrevSalarieConjoint.ImgGraphInval}
            TabDeces={ResultatSalarie.state.PrevSalarieConjoint.TabDeces}
            TabRente={ResultatSalarie.state.PrevSalarieConjoint.TabRente}
            InfosRetraiteChoixDepart={ResultatSalarie.state.RetraiteConjoint.InfosRetraiteChoixDepart}
            InfosRetraite={ResultatSalarie.state.RetraiteConjoint.InfosRetraite}
            ClientConjoint="Conjoint"
            visible={modalDetailConjoint}
            onClose={() => {
              setModalDetailConjoint(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ResultatPage;
