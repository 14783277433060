import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import Pager from "components/ui/controls/pager/pager";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";

function ContributionSpecifique(props) {
  const context = useContext(AppContext);
  return (
    <>
      <Pager
        pages={[
          {
            content: (
              <Column pad>
                <CardBox>
                  <Column fill>
                    <Row>
                      <DropDown
                        label="Cotisation"
                        value={context.state.TypeCotisationManuelle}
                        options={[
                          {
                            value: "proportionnelle",
                            label: "Proportionnelle",
                          },
                          {
                            value: "forfaitaire",
                            label: "Forfaitaire",
                          },
                        ]}
                        onChange={context.handleInputChange("TypeCotisationManuelle")}
                        normal
                      />
                    </Row>
                    <Row tpad>
                      <table>
                        <thead>
                          <tr>
                            <th> <div className="normal">Libellé</div></th>
                            
                            {context.state.TypeCotisationManuelle === "forfaitaire" && <>
                            <th className="text-center"> <div className="normal"> Salarié</div></th>
                            </>}
                            {context.state.TypeCotisationManuelle === "proportionnelle" && <>
                            <th className="text-center"> <div className="normal"> Assiette</div></th>
                            <th className="text-center"><div className="normal"> Salarié</div> </th>
                            </>}
                            <th className="text-center"> <div className="normal">Employeur</div></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <TextField value={context.state.LibelleCotisationManuelle} onChange={context.handleInputChange("LibelleCotisationManuelle")} normal />
                            </td>
                            {context.state.TypeCotisationManuelle === "proportionnelle" && (
                              <td>
                                <DropDown
                                  value={context.state.AssietteCotisationManuelle}
                                  options={[
                                    {
                                      value: "Salaire brut",
                                      label: "Salaire brut",
                                    },
                                    {
                                      value: "Tranche A",
                                      label: "Tranche A",
                                    },
                                    {
                                      value: "Tranche B",
                                      label: "Tranche B",
                                    },
                                    {
                                      value: "Tranche C",
                                      label: "Tranche C",
                                    },
                                  ]}
                                  onChange={context.handleInputChange("AssietteCotisationManuelle")}
                                  normal
                                />
                              </td>
                            )}
                            <td>
                              <TextField
                                value={context.state.TauxSalarieCotisationManuelle}
                                onChange={context.handleInputChange("TauxSalarieCotisationManuelle")}
                                dataType={context.state.TypeCotisationManuelle === "proportionnelle" ? "ufloat" : "int"}
                                format={context.state.TypeCotisationManuelle === "proportionnelle" ? { thousands: " ", unit: "%", precision: 2 } : { thousands: " ", unit: " €" }}
                             normal
                             />
                            </td>
                            <td>
                              <TextField
                                value={context.state.TauxPatronaleCotisationManuelle}
                                onChange={context.handleInputChange("TauxPatronaleCotisationManuelle")}
                                dataType={context.state.TypeCotisationManuelle === "proportionnelle" ? "ufloat" : "int"}
                                format={context.state.TypeCotisationManuelle === "proportionnelle" ? { thousands: " ", unit: "%", precision: 2 } : { thousands: " ", unit: " €" }}
                             normal
                             />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  </Column>
                </CardBox>
              </Column>
            ),
          },
        ]}
        page={0}
        navButtons={{
          validate: {
            onClick: () => {
              context.handleAddCotisationManuelle(props.Outil, props.ClientConjoint);
              props.onClose();
            },
          },
        }}
      />
    </>
  );
}

export default ContributionSpecifique;
